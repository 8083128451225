// action types
import * as actionTypes from '../actions/actionTypes';
// constants
import {ACCOUNT_TYPES} from '../../constants';

// initial state
const initialState = {
  ui: {
    isHeaderMenuModal: false,
    isDashboardActionsModal: false,
    isTransactionsApplyFilter: false,
    isPasswordRulesModal: false,
    isThereActiveModal: false,
  },
  accountType: ACCOUNT_TYPES.business,
  currentNav: null,
};

// reducer
const sharedReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_TRANSACTIONS_APPLY_FILTER:
      return {
        ...state,
        ui: {
          ...state.ui,
          isHeaderMenuModal: false,
          isDashboardActionsModal: false,
          isTransactionsApplyFilter: !state.ui.isTransactionsApplyFilter,
          isThereActiveModal: !state.ui.isThereActiveModal,
        },
      };
    case actionTypes.HEADER_MAENU_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDashboardActionsModal: false,
          isTransactionsApplyFilter: false,
          isHeaderMenuModal: !state.ui.isHeaderMenuModal,
          isThereActiveModal: false,
        },
      };
    case actionTypes.DASHBOARD_ACTIONS_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isHeaderMenuModal: false,
          isTransactionsApplyFilter: false,
          isDashboardActionsModal: !state.ui.isDashboardActionsModal,
          isThereActiveModal: !state.ui.isThereActiveModal,
        },
      };
    case actionTypes.TOGGLE_PASSWORD_RULES:
      console.log ('PASS MODAL');
      return {
        ...state,
        ui: {
          ...state.ui,
          isHeaderMenuModal: false,
          isTransactionsApplyFilter: false,
          isDashboardActionsModal: false,
          isPasswordRulesModal: !state.ui.isPasswordRulesModal,
        },
      };
    case actionTypes.SET_CURRENT_NAV:
      return {...state, currentNav: action.payload};
    case actionTypes.SET_ACCOUNT_TYPE:
      return {
        ...state,
        accountType: action.payload,
      };
    default:
      return {...state};
  }
};

// exports
export default sharedReducer;
