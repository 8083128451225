// modules
import React from "react";
// assets
import SearchIcon from "../../../assets/img/transactions/search.png";
import UndoIcon from "../../../assets/img/transactions/undo-arrow.png";
// antd
import { Select } from "antd";

const TransactionsSubHeader = () => {
  const { Option } = Select;
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <div className="transactions-sub-header">
      <div className="searchBox">
        <div className="input-field">
          <img src={SearchIcon} alt="Search icon" />
          <input type="text" placeholder="Search" />
        </div>
      </div>
      <div className="right-side">
        <div className="token-selection">
          <Select defaultValue="Select Token" style={{ width: 125 }} onChange={handleChange}>
            <Option value="Select Country">Select Token</Option>
            <Option value="Select Country">Select Token</Option>
          </Select>
        </div>
        <div className="payment-method-selection">
          <Select defaultValue="Payment Method" style={{ width: 160 }} onChange={handleChange}>
            <Option value="Payment Method">Payment Method</Option>
            <Option value="Payment Method">Payment Method</Option>
          </Select>
        </div>
        <div className="status-selection">
          <Select defaultValue="Payment Satatus" style={{ width: 155 }} onChange={handleChange}>
            <Option value="Payment Status">Payment Status</Option>
            <Option value="Payment Status">Payment Status</Option>
          </Select>
        </div>
        <div className="options">
          <button>Search</button>
          <div className="btn-reset">
            <img src={UndoIcon} alt="Undo icon" />
            <button>Reset</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsSubHeader;
