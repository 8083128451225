/* eslint-disable react-hooks/exhaustive-deps */
// modules
import React, { useEffect, useState } from "react";
import { KYB_STEPS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
// children
import AccountName from "./AccountName";
// antd
import { Button, Form } from "antd";
// redux
import { submitKybStepOne } from "../../../context/actions";

const KybStepOne = React.memo(function StepOne({ nextStepHandler }) {
  // dispatcher
  const dispatcher = useDispatch();

  // antd form states and methods
  const [form] = Form.useForm();
  const [v, setV] = useState(null);

  const onFinish = (values) => {
    console.log("Received values of form:", values);

    nextStepHandler(KYB_STEPS.TWO);
    console.log({ values });
    setV(values);
    dispatcher(submitKybStepOne(values));
    console.log({ values });
  };

  // selectors
  const kybData = useSelector((state) => state.kyb.step1);

  useEffect(() => {
    setV(kybData);
    console.log({ v });
  }, []);

  return (
    <div className="kyb-step-one">
      <Form
        form={form}
        className="kyb-step-one"
        name="kyb_form_step_one"
        onFinish={onFinish}
        autoComplete="off">
        <AccountName />
        <div className="kyb-submit">
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
});

export default KybStepOne;
