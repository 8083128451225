// modules & hooks
import React, { useState } from "react";
import { MEDIA_QUERIES } from "../../../constants";
import useWindowSize from "../../../hooks/useWindowSize";
// assets
import SuccessIcon from "../../../assets/img/popup/success-icon.png";
// antd
import { Col, Form, Input, Row } from "antd";

const KYCInfo = ({ data }) => {
  // states
  const [isApproved, setIsApproved] = useState(true);

  // window size
  const windowSize = useWindowSize();

  return (
    <div className="kyc-info infoBx">
      <div className="infoBx-heading">
        <h3 className="container-heading">KYC Status</h3>
        <p className="status">
          <img src={SuccessIcon} alt="Success icon" />
          <span>{isApproved ? "Approved" : null}</span>
        </p>
      </div>
      <div className="info__content">
        <Row gutter={16}>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Prime Trust ID"
              label="Prime Trust ID"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input placeholder="John Deo" disabled={isApproved} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Tax ID Number"
              label="Tax ID Number"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input type="emial" placeholder="John_deo@mail.com" disabled={isApproved} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default KYCInfo;
