// modules & hooks
import React, { useState } from "react";
import { MEDIA_QUERIES } from "../../../constants";
import useWindowSize from "../../../hooks/useWindowSize";
// antd
import { Button, Col, Form, Input, Row } from "antd";
// assets
import SuccessIcon from "../../../assets/img/popup/success-icon.png";
import DownloadIcon from "../../../assets/img/kyc/download-arrow.png";

const UploadedDocs = () => {
  // states
  const [isApproved, setIsApproved] = useState(true);

  // window size
  const windowSize = useWindowSize();

  return (
    <div className="uploadedDocs-info infoBx">
      <div className="infoBx-heading">
        <h3 className="container-heading">KYC Status</h3>
        <p className="status">
          <img src={SuccessIcon} alt="Success icon" />
          <span>{isApproved ? "Approved" : null}</span>
        </p>
      </div>
      <div className="info__content">
        <Row gutter={16}>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="ID Card"
              label="ID Card"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input value="Some ID Crad" placeholder="Some ID Crad" disabled={isApproved} />
              <div className="download-box">
                <Button icon={<img src={DownloadIcon} alt="Download icon" />}>
                  Download Document
                </Button>
              </div>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Address Proof"
              label="Address Proof"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input
                value="Some Address Proof"
                placeholder="Some Address Proof"
                disabled={isApproved}
              />
              <div className="download-box">
                <Button icon={<img src={DownloadIcon} alt="Download icon" />}>
                  Download Document
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UploadedDocs;
