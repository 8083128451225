// modules & hooks
import React, { useState } from "react";
import { MEDIA_QUERIES } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";
// antd
import { Upload, message, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Col, Form, Row, Select } from "antd";
// assets
import DownloadIcon from "../../assets/img/kyc/download-arrow.png";

// props and methods for upload fields
const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const UploadDocuments = ({ isFinished, formData, identityDocsOptions, otherDocsOptions }) => {
  const { Option } = Select;
  const [isIdSelected, setIsIdSelected] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  // window size
  const windowSize = useWindowSize();

  function handleChange(value, type) {
    console.log(`selected ${value}`);
    if (type === "id") {
      setIsIdSelected(true);
    }
    if (type === "other") {
      setIsOtherSelected(true);
    }
  }

  return (
    <div className="kyc-address-details kyc-form">
      <h3 className="container-heading">Upload Documents</h3>
      <Row gutter={16}>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="identityDocument"
            label="Identity Document"
            rules={[
              {
                required: true,
              },
            ]}>
            <Select onChange={(e) => handleChange(e, "id")} disabled={isFinished}>
              {identityDocsOptions?.map((e) => (
                <Option value={e.id} key={e.id}>
                  {e.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="uploadIdentityDocument"
            label="Upload Identity Docuement"
            rules={[
              {
                required: true,
              },
            ]}>
            {isFinished ? (
              <div className="download-box">
                <span>{formData["uploadIdentityDocument"]?.file?.name}</span>{" "}
                <Button prefix={DownloadIcon}>Download Document</Button>
              </div>
            ) : (
              <Upload {...props} multiple={false}>
                <Button icon={<UploadOutlined />} disabled={!isIdSelected || isFinished}>
                  Drag files here or <span>Browse files</span>
                </Button>
              </Upload>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item name="otherDocument" label="Other Document" rules={[]}>
            <Select onChange={(e) => handleChange(e, "other")} disabled={isFinished}>
              {otherDocsOptions?.map((e) => (
                <Option value={e.id} key={e.id}>
                  {e.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item name="uploadOtherDocument" label="Upload Other Document" rules={[]}>
            {isFinished ? (
              <div className="download-box">
                <span>{formData["uploadOtherDocument"]?.file?.name}</span>
                <Button prefix={DownloadIcon}>Download Document</Button>
              </div>
            ) : (
              <Upload {...props} multiple={false}>
                <Button icon={<UploadOutlined />} disabled={!isOtherSelected || isFinished}>
                  Drag files here or <span>Browse files</span>
                </Button>
              </Upload>
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default UploadDocuments;
