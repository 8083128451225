/* eslint-disable no-unused-vars */
// modules
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// children
import PasswordRules from "../../UI/PasswordRules";
// assets
import SuccessIcon from "../../assets/img/popup/success-icon.png";
// antd
import { Form, Input, Checkbox, Button, Space, DatePicker, Modal, Select } from "antd";
import { Link, Redirect, useHistory } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
// context
import { togglePasswordRules } from "../../context/actions";
// services
import { Context } from "../../../store";
import { USER_TYPE } from "eurst-shared/src/enums";
import Cookies from "js-cookie";
import moment from "moment";
import auth from "../../../services/auth";
import { InfoCircleFilled } from "@ant-design/icons";
import useWindowSize from "../../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../../constants";

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const RegisterForm = ({ formType, countries }) => {
  const formTypes = { private: "PRIVATE", business: "BUSINESS" };
  const [countryCode, setCountryCode] = useState("");

  // states
  const [isConfirmPasswordModal, setIsConfirmPasswordModal] = useState(false);

  const toggleConfirmModalHandler = (bool) => {
    if (isConfirmPasswordModal) {
      setIsConfirmPasswordModal(false);
      return;
    }
    setIsConfirmPasswordModal(bool);
    dispatcher(togglePasswordRules());
  };

  const [form] = Form.useForm();
  const [size] = useState("default");

  // dispatcher
  const dispatcher = useDispatch();

  const togglePasswordRulesHandler = (e) => {
    e.stopPropagation();
    toggleConfirmModalHandler(false);
  };

  // selectors
  const isActivePasswordRules = useSelector((state) => state.shared.ui.isPasswordRulesModal);

  // popups states
  const [visible, setVisible] = useState(false);
  const [privacyVisible, setPrivacyVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "You have successfully registered an account. Please check out your email and verify your account."
  );

  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useContext(Context);

  const history = useHistory();

  // api functionality
  const dateFormatList = ["YYYY/MM/DD", "YY/MM/DD"];

  // if (!state.countryCode) {
  //   return <Redirect to="/check-country" />;
  // }

  const [password, setPassword] = useState(null);
  console.log({ password });

  const onFinish = async (values) => {
    // values = { ...values, password };
    values.password = password;
    values.countryCode = Cookies.get("countryCode");
    values.type = USER_TYPE.naturalPerson;
    values.dateOfBirth = moment(values?.dateOfBirth).format("YYYY-MM-DD");

    setLoading(true);
    try {
      const registrationResponse = await auth.registration(values);
      if (registrationResponse.status === 200) {
        showModal();
        history.push("/");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    console.log({ values });
  };

  const handlerFieldsChange = (changedFields, allFields) => {
    if (
      allFields.some(function (field) {
        return field.errors.length > 0 || field.touched === false;
      })
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  // antd select utils
  const { Option } = Select;
  function handleChange(value) {
    console.log(`selected ${value}`);
    setCountryCode(value);
    Cookies.set("countryCode", value);
    dispatch({ type: "SET_COUNTRY_CODE", payload: value });
  }

  // popup show method
  const showModal = () => {
    setVisible(true);
  };

  const showPrivacyPolicyModal = () => {
    setPrivacyVisible(true);
  };

  const showTermsPolicyModal = () => {
    setTermsVisible(true);
  };
  // popup OK method
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
      history.push("/login", []);
    }, 1000);
    form.resetFields();
  };

  const handleOkPrivacy = () => {
    setPrivacyVisible(false);
  };

  const handleOkTerms = () => {
    setTermsVisible(false);
  };

  // popup cancel menthods
  const handleCancel = () => {
    setVisible(false);
  };

  const handleCancelPrivacy = () => {
    setPrivacyVisible(false);
  };

  const handleCancelTerms = () => {
    setTermsVisible(false);
  };

  const disabledDate = (current) => {
    const start = moment("1900-01-01", "YYYY-MM-DD");
    return current < start || current > moment();
  };

  // window size
  const windowSize = useWindowSize();

  return (
    <Form
      form={form}
      layout={"vertical"}
      size={"large"}
      name="register"
      onFieldsChange={handlerFieldsChange}
      onFinish={onFinish}
      initialValues={{}}
      scrollToFirstError>
      <Form.Item
        name="country"
        label="Select Country"
        rules={[
          {
            required: true,
          },
        ]}>
        <Select onChange={handleChange} defaultValue="India" className="register-select-country">
          {countries?.map((e) => (
            <Option value={e.code} key={e.code} disabled={!e.available}>
              {e.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="email"
        label="Email Address"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}>
        <Input placeholder="John_deo@emial.com" />
      </Form.Item>
      {formType === formTypes.private ? (
        <>
          <Form.Item
            name="userName"
            label="Create User Name"
            rules={[
              {
                required: true,
                message: "Please input your nickname!",
                whitespace: true,
              },
            ]}>
            <Input placeholder="John Deo" />
          </Form.Item>
          <Form.Item
            name="dateOfBirth"
            label="Date Of Birth"
            rules={[
              {
                required: true,
                message: "Please input your date of birth!",
                // whitespace: true,
              },
            ]}>
            {/* <Space direction="vertical" size={12}> */}
            <DatePicker
              size={size}
              inputReadOnly
              placeholder={"YEAR/MM/DD"}
              disabledDate={disabledDate}
              format={dateFormatList}
            />
            {/* </Space> */}
          </Form.Item>
        </>
      ) : (
        <>
          <Form.Item
            name="userName"
            label="Create Company Name"
            rules={[
              {
                required: true,
                message: "Please input your company name!",
                whitespace: true,
              },
            ]}>
            <Input placeholder="John Deo" />
          </Form.Item>
        </>
      )}
      <div className="password-rules-container">
        <InfoCircleFilled className="info-icon" onClick={togglePasswordRulesHandler} />
        {isActivePasswordRules ? (
          <PasswordRules isConfirmPassword={isConfirmPasswordModal} />
        ) : null}
      </div>
      <Form.Item
        className="password-field"
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
        hasFeedback>
        <Input.Password
          placeholder="123456"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Item>

      <InfoCircleFilled className="info-icon" onClick={() => toggleConfirmModalHandler(true)} />
      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error("The two passwords that you entered do not match!"));
            },
          }),
        ]}>
        <Input.Password placeholder="123456" />
      </Form.Item>

      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error("Should accept agreement")),
          },
        ]}
        {...tailFormItemLayout}>
        <Checkbox
          style={{
            display: "block",
            maxWidth: "100%",
          }}>
          <span>I have read the</span>
          <div className="terms-of-use-container">
            <Button type="primary" onClick={showTermsPolicyModal} className="link">
              Terms of use
            </Button>
            <Modal
              className="terms-of-use-popup"
              visible={termsVisible}
              centered
              okText="Done"
              onOk={handleOkTerms}
              cancelText="Close"
              onCancel={handleCancelTerms}>
              <TermsOfUse handleCancelTerms={handleCancelTerms} />
            </Modal>
          </div>
          <span className="nowrap">and the</span>
          <div className="privacy-policy-container">
            <Button type="primary" onClick={showPrivacyPolicyModal} className="link">
              Privacy policy
            </Button>
            <Modal
              className="privacy-policy-popup"
              visible={privacyVisible}
              centered
              okText="Done"
              onOk={handleOkPrivacy}
              cancelText="Close"
              onCancel={handleCancelPrivacy}>
              <PrivacyPolicy handleCancelPrivacy={handleCancelPrivacy} />
            </Modal>
          </div>
        </Checkbox>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
        <Modal
          className="register-success"
          title={
            <>
              <img src={SuccessIcon} alt="Success icon" />
              <span>Congartulations</span>
            </>
          }
          visible={visible}
          centered
          okText="Done"
          onOk={handleOk}
          onCancel={handleCancel}
          confirmLoading={confirmLoading}>
          <p>{modalText}</p>
        </Modal>
      </Form.Item>
      <Form.Item>
        <p>
          Already have an account? <Link to="/main/login">Login</Link>
        </p>
      </Form.Item>
    </Form>
  );
};

export default RegisterForm;
