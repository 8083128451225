// exports
export const MEDIA_QUERIES = {MD: 1300, SM: 900, XS: 460};

export const ACCOUNT_TYPES = {
  private: 'PRIVATE',
  business: 'BUSINESS',
};
export const PROFILE_INFO_CTAEGORIES = {
  BASIC_INFO: 'Basic Information',
  KYC_INFO: 'KYC Information',
  KYB_INFO: 'Kyb Information',
  WALLET_INFO: 'Wallet Information',
  UPLOADED_DOCS: 'Uploaded Documents',
};

export const KYB_STEPS = {ONE: 'ONE', TWO: 'TWO', THREE: 'THREE', FOUR: 'FOUR'};
export const KYB_BACKS = {
  BACK_TO_ONE: 'ONE',
  BACK_TO_TWO: 'TWO',
  BACK_TO_THREE: 'THREE',
};
