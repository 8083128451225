// modules & hooks
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// children
import BeneficialEntityInfo from "./BeneficialEntityInfo";
import BeneficialEntityAddress from "./BeneficialEntityAddress";
import BeneficialEntityDocVerification from "./BeneficialEntityDocVerification";
import BeneficialOwnershipModal from "../../../../UI/BeneficialOwnershipModal";
import { Avatar, Button, Form } from "antd";
// assets
import UpArrow from "../../../../assets/img/kyc/up-arrow.png";
import DownArrow from "../../../../assets/img/kyc/down-arrow.png";
import { KYB_STEPS } from "../../../../constants";
import { saveBeneficialEntity } from "../../../../context/actions";

const KybOwners = ({ isExpandedEntityContent, toggleExpandedEntityContent }) => {
  // dispatcher
  const dispatcher = useDispatch();

  // antd form states and methods
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values of form:", values);
    dispatcher(saveBeneficialEntity(values));
  };

  return (
    <div className="kyb-control-persons">
      <h4 className="container-heading">Beneficial Owners and/or Control Persons</h4>
      <div className="add-beneficial-entity-content">
        <div className="heading">
          <div className="user-info">
            <Avatar className="avatar" />
            <h4 className="username">John Doe</h4>
          </div>
          <div className="toggle-icon">
            <img
              src={isExpandedEntityContent ? UpArrow : DownArrow}
              alt="Up arrow"
              onClick={toggleExpandedEntityContent}
            />
          </div>
        </div>
        {isExpandedEntityContent ? (
          <div className="content">
            <Form form={form} onFinish={onFinish}>
              <BeneficialEntityInfo stepNumber={KYB_STEPS.THREE} />
              <BeneficialEntityAddress />
              <BeneficialEntityDocVerification />
              <div className="options">
                <Button className="save" htmlType="submit">
                  Save
                </Button>
              </div>
            </Form>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default KybOwners;
