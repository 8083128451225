// modules
import React, { useState, useContext, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import Cookies from "js-cookie";
// assets
import ViewIcon from "../../assets/img/login/view.png";
// antd
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
// services
import auth from "../../../services/auth";
import { Context } from "../../../store";
import { getOwnerSettings } from "../../../services/general";

const LoginForm = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const onFinish = async (values) => {
    ReactGA.event({
      category: "Sign Up",
      action: "User pressed the big blue log in button",
    });
    setLoading(true);
    try {
      const token = await auth.login(values);
      if (token) {
        Cookies.set("token", token.data.token.token);
        Cookies.set("status", token.data.status);
        const response = await getOwnerSettings();
        dispatch({ type: "SET_OWNER_SETTINGS", payload: response.data });
        history.push("/main/dashboard");
      }
    } catch (error) {
      console.error("login page", error);
    } finally {
      setLoading(false);
    }
  };

  const onCancelClick = () => {
    history.goBack();
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "Is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const handlerFieldsChange = (changedFields, allFields) => {
    if (
      allFields.some(function (field) {
        return field.errors.length > 0 || field.touched === false;
      })
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      history.push("/main/dashboard");
    }
  }, []);
  console.log("disabled", disabled);

  return (
    <div>
      <Form
        form={form}
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFieldsChange={handlerFieldsChange}
        validateMessages={validateMessages}
        onFinish={onFinish}>
        <div className="login-form-heading">
          <h3 className="container-heading">Login</h3>
          <p>Please enter your registered email and password.</p>
        </div>
        <Form.Item
          name="email"
          label="Email Address"
          rules={[
            {
              required: true,
              message: "Please input your registered email address!",
              whitespace: true,
            },
            { type: "email" },
          ]}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            onChange={() => console.log("hi")}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback>
          <Input.Password placeholder="password" />
        </Form.Item>
        <Link className="login-form-forgot" to="/reset-password" style={{ marginLeft: "auto" }}>
          Forgot password
        </Link>
        <Form.Item style={{ marginTop: "1rem" }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabled}
            loading={loading}
            className="login-form-button">
            Log in
          </Button>
          <div className="submit-message">
            <span>Do not have an account?</span>
            <Link className="login-form-forgot" to="/registration">
              Register
            </Link>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginForm;
