// modules
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
// components
import EmailSentMessage from "./EmailSentMessage";
// assets
import SuccessIcon from "../../assets/img/popup/success-icon.png";
import BackIcon from "../../assets/img/reset-password/back-arrow.png";
// antd
import { Form, Input, Button, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";

// services
import Cookies from "js-cookie";
import auth from "../../../services/auth";

const ResetPasswordForm = () => {
  // states
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  // antd modal states & methods
  const [isModalVisible, setIsModalVisible] = useState(false);

  // popup methods
  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      history.push("/main/dashboard");
    }
  }, [history]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await auth.resetPassword(values.email);
      setFormData(values);
      showModal();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "Is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  // back to login
  const backToLogin = () => {
    history.push("/login");
  };

  return (
    <div>
      <Form
        name="basic"
        initialValues={{ email: "" }}
        onFinish={onFinish}
        onFieldsChange={([email]) => setIsEmailValid(!email.errors.length)}
        validateMessages={validateMessages}>
        <div className="reset-password-form-heading">
          <div className="heading-nav" onClick={backToLogin}>
            <img src={BackIcon} alt="Back arrow icon" />
            <span>Back</span>
          </div>
          <div className="heading-content">
            <h3 className="container-heading">Reset your password</h3>
            <p>
              Please enter your registered email and password. We will send confirmation link to
              reset your password.
            </p>
          </div>
        </div>
        <Form.Item
          name="email"
          label="Email Address"
          rules={[
            {
              required: true,
              message: "Please input your registered email address!",
              whitespace: true,
            },
            { type: "email" },
          ]}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            onChange={() => console.log("hi")}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item>
          <Button
            disabled={!isEmailValid}
            className="primary-button"
            type="primary"
            htmlType="submit"
            loading={loading}>
            Submit
          </Button>
          <Modal
            className="email-sent-modal"
            title={
              <>
                <img src={SuccessIcon} alt="Success icon" />
                <span>Email Sent!</span>
              </>
            }
            visible={isModalVisible}
            centered
            footer={[]}>
            <EmailSentMessage email={formData?.email} setIsModalVisible={setIsModalVisible} />
          </Modal>
          <div className="submit-message">
            <span>Remember Password?</span>
            <Link className="reset-password-form-forgot" to="/login">
              Login
            </Link>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
