/* eslint-disable */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  PT_DOCUMENT_TYPES,
  PT_DOCUMENT_TYPES_AS_OBJECT,
  USER_STATUSES_AS_OBJECT,
} from "eurst-shared/src/enums";

import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import Col from "antd/lib/grid/col";
import Divider from "antd/lib/divider";
import Dragger from "antd/lib/upload/Dragger";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import {
  getPersonalDataFromLs,
  removePersonalDataFromLs,
  postPersonalInfo,
  setKycStatus,
  getPreviewAgreement,
} from "../../services/users";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import InboxOutlined from "@ant-design/icons/lib/icons/InboxOutlined";
import BackSideMessage4Upload from "../../components/BacksideMessage4Upload";
import { REQUIRED_PT_DOC_TYPES } from "../../constants/constants";
import { getInstance } from "../../utils/helpers";
import "./index.scss";
import { Context } from "../../store";
import Spin from "antd/lib/spin";
import Modal from "antd/es/modal";

function UploadKYCPage() {
  const history = useHistory();
  const [identityDoc, setIdentityDoc] = useState({});
  const [otherDoc, setOtherDoc] = useState({});
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [identityDocFiles, setIdentityDocFiles] = useState([]);
  const [otherDocFiles, setOtherDocFiles] = useState([]);
  const [approval, setApproval] = useState(false);
  const [previewAgreement, setPreviewAgreement] = useState("");
  const personalInfo = useMemo(() => getPersonalDataFromLs(), []);
  const [state] = useContext(Context);

  const refAgreement = useRef(null);

  useEffect(async () => {
    const payload = {
      ...personalInfo.personalInfo,
      ...personalInfo.residenceInfo,
      phoneNumber: personalInfo.personalInfo.phoneNumber,
    };
    const previewAgreementHTML = await getPreviewAgreement(payload);
    setPreviewAgreement(previewAgreementHTML.data.content);
  }, []);

  if (state.user && ![USER_STATUSES_AS_OBJECT.kycFilling].includes(state.user.status)) {
    return <Redirect to="/main/dashboard" />;
  }

  // if (!personalInfo) {
  //   return <Redirect to="/personal-details" />;
  // }

  // list of documents for identity confirmation
  const identityDocsOptions = PT_DOCUMENT_TYPES.filter((docType) =>
    REQUIRED_PT_DOC_TYPES.some((id) => id === docType.id)
  );

  // list of other documents should exclude all documents for identity confirmation
  const otherDocsOptions = PT_DOCUMENT_TYPES.filter(
    (docType) => !identityDocsOptions.some((type) => type.id === docType.id)
  );

  const uploadDocuments = async () => {
    setLoading(true);

    try {
      setLoading(true);
      await postPersonalInfo(personalInfo.personalInfo, personalInfo.residenceInfo);

      const files = [...identityDocFiles, ...otherDocFiles];
      const url = `/api/users/personal-info/upload/${identityDocFiles[0].id}`;
      const data = new FormData();
      data.append("file", identityDocFiles[0]);

      const defaultHeaders = getInstance().defaults.headers;
      const config = {
        headers: {
          ...defaultHeaders,
          "content-type": "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
        },
      };

      const parent = await getInstance().post(url, data, config);

      if (identityDocFiles.length > 1) {
        const url = `/api/users/personal-info/upload/${identityDocFiles[1].id}/parent/${parent.data.history.ptDocumentId}`;
        const data = new FormData();
        data.append("file", identityDocFiles[1]);

        const defaultHeaders = getInstance().defaults.headers;
        const config = {
          headers: {
            ...defaultHeaders,
            "content-type": "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
          },
        };

        await getInstance().post(url, data, config);
      }

      const currentDocType = PT_DOCUMENT_TYPES.find(
        (docType) => docType.id === identityDocFiles[0].id
      );
      toast(`${currentDocType.label} file uploaded successfully.`, {
        type: "success",
        position: "bottom-right",
      });

      await Promise.all(
        otherDocFiles.map(async (docFile) => {
          const url = `/api/users/personal-info/upload/${docFile.id}`;
          const data = new FormData();
          data.append("file", docFile);

          const defaultHeaders = getInstance().defaults.headers;
          const config = {
            headers: {
              ...defaultHeaders,
              "content-type":
                "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
            },
          };

          await getInstance().post(url, data, config);

          const currentDocType = PT_DOCUMENT_TYPES.find((docType) => docType.id === docFile.id);

          toast(`${currentDocType.label} file uploaded successfully.`, {
            type: "success",
            position: "bottom-right",
          });
        })
      );

      await setKycStatus();

      toast(`The KYC form has been filled!`, {
        type: "success",
        position: "bottom-right",
      });

      removePersonalDataFromLs();
      history.push("/main/dashboard");
    } catch (error) {
      console.error(error);
      toast(`${error?.data?.message}`, {
        type: "error",
        position: "bottom-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const identityDocProps = {
    disabled: !identityDoc.label,
    fileList: identityDocFiles,
    customRequest: ({ file, onSuccess }) => {
      file.id = identityDoc.id;
      setIdentityDocFiles((prev) => [...prev, file]);
      onSuccess();
    },
    onRemove: (file) => {
      setIdentityDocFiles((prev) => [...prev.filter((doc) => doc.uid !== file.uid)]);
    },
  };

  const otherDocProps = {
    disabled: !otherDoc.label,
    fileList: otherDocFiles,
    customRequest: ({ file, onSuccess }) => {
      file.id = otherDoc.id;
      setOtherDocFiles((prev) => [...prev, file]);
      onSuccess();
    },
    onRemove: (file) => {
      setOtherDocFiles((prev) => [...prev.filter((doc) => doc.uid !== file.uid)]);
    },
  };

  let doc = null;

  if (previewAgreement) {
    doc = new DOMParser().parseFromString(previewAgreement, "text/html");
    const element = doc.querySelector("table.pt__signature_data tbody tr td");

    const newInput = doc.createElement("input");
    const newLabel = doc.createElement("label");
    newLabel.textContent = "authorized signature";
    newLabel.style.marginLeft = "20px";
    newInput.value = "vfecedxrf";
    const newValue = `${personalInfo.personalInfo.firstName} ${personalInfo.personalInfo.lastName}`;
    element.innerHTML = '<input disabled value="' + newValue + '" />';
    element.append(newLabel);
  }

  let disabledButton =
    [
      PT_DOCUMENT_TYPES_AS_OBJECT["drivers_license"],
      PT_DOCUMENT_TYPES_AS_OBJECT["residence_permit"],
    ].includes(identityDoc.id) && identityDocFiles.length < 2;

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Row className="upload-kyc-wrap" align={"flex-start"} justify={"center"}>
        <Space direction="vertical">
          <h2>Provide your documents</h2>
          <p className="upload-kyc-desc">
            This step is necessary to get access to the full range of EURST operations
          </p>
          <Divider className="upload-devider" orientation="left">
            Documents
          </Divider>
          <Row align={"middle"} justify={"center"} wrap>
            <Col className="upload-kyc-settings" sm={24} md={6} style={{ margin: "2rem 0" }}>
              <h3>Confirm Your Identity</h3>
              <Dropdown
                trigger={["click"]}
                disabled={identityDocFiles?.length > 0}
                overlay={
                  <CustomDropOptions options={identityDocsOptions} onClick={setIdentityDoc} />
                }>
                <a onClick={(e) => e.preventDefault()}>
                  {identityDoc.label || "Choose a document type"} <DownOutlined />
                </a>
              </Dropdown>
            </Col>
            <Col className="upload-drag-wrap" sm={24} md={18}>
              <CustomDragger uniqueProps={identityDocProps} docType={identityDoc} />
            </Col>
          </Row>
          <Divider />
          <Row align={"middle"} justify={"center"} wrap>
            <Col className="upload-kyc-settings" sm={24} md={6} style={{ margin: "2rem 0" }}>
              <h3>
                Other Documents <br />
                (optional)
              </h3>
              <Dropdown
                trigger={["click"]}
                disabled={otherDocFiles?.length > 0}
                overlay={<CustomDropOptions options={otherDocsOptions} onClick={setOtherDoc} />}>
                <a onClick={(e) => e.preventDefault()}>
                  {otherDoc.label || "Choose a document type"} <DownOutlined />
                </a>
              </Dropdown>
            </Col>
            <Col className="upload-drag-wrap" sm={24} md={18}>
              <CustomDragger uniqueProps={otherDocProps} docType={otherDoc} />
            </Col>
          </Row>
          <Row align={"middle"} justify={"center"} wrap>
            <Col sm={24} style={{ margin: "2rem 0" }}>
              <label className="finish-kyc-checkbox">
                <Checkbox checked={approval} disabled={!approval} /> I give my personal consent to
                check the data provided in this KYC form.
              </label>
              <a
                href={"#"}
                style={{ display: "block", textAlign: "left" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setVisible(true);
                }}>
                See Preview Agreement
              </a>
              <p className="kyc-finish-note">
                Note:
                <br />
                The KYC procedure check requires 1-5 days.
                <br />
                You will receive an email when it is complete.
                <br />
                After successful completion you will have access to the services.
                <br />
              </p>
            </Col>
          </Row>
          <Row>
            <Button
              className="primary-button"
              disabled={!identityDocFiles?.length || !approval || disabledButton}
              loading={loading}
              onClick={uploadDocuments}
              type="primary">
              Upload documents and finish
            </Button>
          </Row>
        </Space>
        <Modal
          title="Self-Directed Custodial Account Agreement"
          centered
          visible={visible}
          onOk={() => {
            setVisible(false);
            setApproval(true);
          }}
          okText={"Agree"}
          onCancel={() => {
            setVisible(false);
            setApproval(false);
          }}
          width={1000}>
          <div
            ref={refAgreement}
            dangerouslySetInnerHTML={{
              __html: doc ? new XMLSerializer().serializeToString(doc) : "",
            }}></div>
        </Modal>
      </Row>
    </Spin>
  );
}

export default UploadKYCPage;

const BackSides = ({ docType }) => {
  const withBackside = PT_DOCUMENT_TYPES.filter((r) => r.id === docType && r.backside);

  if (withBackside.length > 0) {
    return <p className="ant-upload-hint">Please, upload both sides of the document.</p>;
  }
  return null;
};

const CustomDragger = ({ uniqueProps, docType }) => {
  const onPreview = async (file) => {
    if (/image/.test(file.type)) {
      let src = file.url;

      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
        });
      }

      const image = new Image();
      image.src = src;

      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    } else if (/pdf/.test(file.type)) {
      const pdfFile = new Blob([file], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(pdfFile);

      window.open(fileURL);
    } else {
      console.error(
        "There was a problem with the file preview!\n Please report this issue to the development team. Thank you =)"
      );
    }
  };

  const beforeUpload = (file) => {
    try {
      const isFileUnique = uniqueProps.fileList.every(
        (doc) => doc.size !== file.size && doc.name !== file.name
      );
      if (!isFileUnique) throw "You have already selected this file!\nDon't duplicate, please.";

      const hasFileFitSize = file.size < 5000000;
      if (!hasFileFitSize) throw "Your document is too large!\nTry another file, please.";

      const hasFileAllowedExtension = /image/.test(file.type) || /pdf/.test(file.type);
      if (!hasFileAllowedExtension)
        throw "You can upload only pictures and PDF!\nTry another file, please.";

      const isAmountLimit = uniqueProps.fileList?.length < 5;
      if (!isAmountLimit) throw "You can upload only 5 documents!";
    } catch (message) {
      toast(message, {
        type: "warning",
        position: "bottom-right",
      });
      return false;
    }
  };

  return (
    <Dragger
      accept="image/*, .pdf"
      listType="picture"
      multiple={false}
      showUploadList
      onPreview={onPreview}
      beforeUpload={beforeUpload}
      {...uniqueProps}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Select a document type,</p>
      <p className="ant-upload-text">then drag and drop or select a file here.</p>
      <p className="ant-upload-hint">{`Upload a copy of а valid ${
        Object.keys(docType).length !== 0 ? docType.label : "document"
      } in colour.`}</p>

      <BackSides docType={docType.id} />

      <p className="ant-upload-hint">The image must be high quality, unobstructed and uncropped.</p>
      <p className="ant-upload-hint">
        The image must show a full document page (all 4 corners must be visible)
      </p>
    </Dragger>
  );
};

CustomDragger.propTypes = {
  uniqueProps: PropTypes.object,
};

const CustomDropOptions = ({ options, onClick }) => (
  <Menu subMenuCloseDelay triggerSubMenuAction={"click"}>
    {options.map((docType) => (
      <Menu.Item key={docType.id} onClick={() => onClick(docType)}>
        {docType.label}
      </Menu.Item>
    ))}
  </Menu>
);

CustomDropOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
};
