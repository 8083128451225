// modules
import React from "react";
import { useSelector } from "react-redux";
import { ACCOUNT_TYPES, PROFILE_INFO_CTAEGORIES } from "../../../constants";
// components
import BasicInfo from "./BasicInfo";
import KybInfo from "./KybInfo";
import KycInfo from "./KYCInfo";
import UploadedDocs from "./UploadedDocs";
import WalletInfo from "./WalletInfo";

const InfoBox = ({ infoCategory, basicInfo, kycInfo, kybInfo, walletInfo, uploadDoc }) => {
  // cheching account type
  const isBusinessAccount =
    useSelector((state) => state.shared.accountType) === ACCOUNT_TYPES.business;

  return (
    <div className="infoBox">
      {infoCategory === "Basic Information" ? (
        <BasicInfo data={basicInfo} />
      ) : infoCategory === PROFILE_INFO_CTAEGORIES.KYC_INFO && !isBusinessAccount ? (
        <KycInfo data={kycInfo} />
      ) : infoCategory === PROFILE_INFO_CTAEGORIES.KYB_INFO && isBusinessAccount ? (
        <KybInfo data={kycInfo} />
      ) : infoCategory === PROFILE_INFO_CTAEGORIES.WALLET_INFO ? (
        <WalletInfo data={walletInfo} />
      ) : infoCategory === PROFILE_INFO_CTAEGORIES.UPLOADED_DOCS ? (
        <UploadedDocs data={uploadDoc} />
      ) : null}
    </div>
  );
};

export default InfoBox;
