// modules & hooks
import React, { useState } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../../../constants";
// antd
import { Button, Col, Form, Input, message, Row, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
// assets
import DownloadIcon from "../../../assets/img/kyc/download-arrow.png";
import { useSelector } from "react-redux";
import { useEffect } from "react";

// props and methods for upload fields
const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const EntityDocVerification = () => {
  // selectors
  const kybData = useSelector((state) => state.kyb.step2.entityDocumentVerification);
  const isKybSubmitted = useSelector((state) => state.kyb.isKybSubmitted);

  const [isIdSelected, setIsIdSelected] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  // antd select methods
  const { Option } = Select;

  function handleChange(value, type) {
    console.log(`selected ${value}`);
    if (type === "id") {
      setIsIdSelected(true);
    }
    if (type === "other") {
      setIsOtherSelected(true);
    }
  }

  const [formData, setFormData] = useState(kybData);

  useEffect(() => {
    setFormData(kybData);
  }, []);

  // window size
  const windowSize = useWindowSize();

  return (
    <div className="kyb-contact-info">
      <h3 className="container-heading">Entity Document Verification</h3>

      <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
        <Form.Item
          name="taxCountry"
          label="Tax Country"
          rules={
            !isKybSubmitted && !kybData?.taxCountry
              ? [
                  {
                    required: true,
                  },
                ]
              : null
          }
          style={{ display: "flex", flexDirection: "column" }}>
          <Select
            defaultValue={formData?.taxCountry || "+91"}
            onChange={handleChange}
            disabled={isKybSubmitted}>
            <Option value="+91">+91</Option>
            <Option value="+20">+20</Option>
          </Select>
        </Form.Item>
      </Col>

      <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
        <Form.Item
          name="registrationNumber"
          label="Registration Number"
          rules={
            !isKybSubmitted && !kybData?.registrationNumber
              ? [
                  {
                    required: true,
                  },
                ]
              : null
          }>
          <Input
            type="text"
            value={formData?.registrationNumber}
            defaultValue={formData?.registrationNumber}
            placeholder="Enter Registration Number"
            disabled={isKybSubmitted}
          />
        </Form.Item>
      </Col>

      <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
        <Row gutter={16}>
          <Form.Item
            name="regionOfFormation"
            label="Region Of Formation"
            rules={
              !isKybSubmitted && !kybData?.regionOfFormation
                ? [
                    {
                      required: true,
                    },
                  ]
                : null
            }>
            <Row>
              <Col>
                <Input
                  type="text"
                  value={formData?.regionOfFormation}
                  defaultValue={formData?.regionOfFormation}
                  placeholder="Enter Region"
                  disabled={isKybSubmitted}
                />
              </Col>
            </Row>
          </Form.Item>
        </Row>
      </Col>

      <Col>
        <Form.Item
          name="proofOfAddress"
          label="Proof Of Address"
          rules={
            !isKybSubmitted && !kybData?.proofOfAddress
              ? [
                  {
                    required: true,
                  },
                ]
              : null
          }>
          {isKybSubmitted ? (
            <div className="download-box">
              <span>{kybData?.file?.name}</span>
              <Button prefix={DownloadIcon}>Download Document</Button>
            </div>
          ) : (
            <Upload {...props} multiple={false} className="upload-box">
              <Button disabled={isKybSubmitted}>
                Drag files here or <span>Browse files</span>
              </Button>
            </Upload>
          )}
        </Form.Item>
      </Col>

      <Col>
        <Form.Item
          name="proofOfEntityFormation"
          label="Proof Of Entity Formation"
          rules={
            !isKybSubmitted && !kybData?.proofOfEntityFormation
              ? [
                  {
                    required: true,
                  },
                ]
              : null
          }>
          {isKybSubmitted ? (
            <div className="download-box">
              <span>{kybData?.file?.name}</span>
              <Button prefix={DownloadIcon}>Download Document</Button>
            </div>
          ) : (
            <Upload {...props} multiple={false} className="upload-box">
              <Button disabled={isKybSubmitted}>
                Drag files here or <span>Browse files</span>
              </Button>
            </Upload>
          )}
        </Form.Item>
      </Col>
    </div>
  );
};

export default EntityDocVerification;
