// modules
import React, { useState } from "react";
// assets
import AlertIcon from "../../../assets/img/dashboard/alert-icon.png";
// antd
import { Button, Form, Input, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
// services
import isEthereumAddress from "validator/es/lib/isEthereumAddress";
import { postWallet } from "../../../../services/wallet";
// import { getUser } from "../../../../services/users";

const AddYourWallet = ({ user }) => {
  // states
  const [isVisible, setIsVisible] = useState(false);
  const [modalText, setModalText] = useState(
    "Please enter your wallet address and complete your KYC to proceed."
  );
  const [form] = Form.useForm();

  const noticeText =
    "This ETH address will be tied to your EURST account. This can't be changed unless you create a different user account.";

  const onFinish = async (values) => {
    try {
      const walletResponse = await postWallet(values.walletAddress);
      if (walletResponse.data.balance) {
        // const data = await getUser();
        console.log("data", walletResponse);
        setIsVisible(true);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  // popup OK method
  const handleOk = () => {
    setIsVisible(false);
  };

  // popup cancel menthods
  const handleCancel = () => {
    setIsVisible(false);
  };

  const isWalletAvaialble = !!user?.ethAddress;

  return (
    <div className="add-wallet">
      <h3 className="container-heading">Add Your Wallet</h3>
      {!isWalletAvaialble && (
        <div className="add-wallet-warning">
          <ExclamationCircleFilled />
          <p>{noticeText}</p>
        </div>
      )}
      {!isWalletAvaialble ? (
        <Form form={form} name="ethAddress" onFinish={onFinish}>
          <div className="form-container">
            <div className="input__field">
              <Form.Item
                name="walletAddress"
                rules={[
                  {
                    required: true,
                    validator: async (_, name) => {
                      if (!name) {
                        return Promise.reject(new Error("Please enter Wallet address"));
                      }
                      if (!isEthereumAddress(name)) {
                        return Promise.reject(new Error("Invalid Wallet address"));
                      }
                    },
                  },
                ]}>
                <Input placeholder="Enter Wallet Address" />
              </Form.Item>
              <Form.Item className="btn">
                <Button type="primary" htmlType="submit">
                  Confirm
                </Button>
              </Form.Item>
              <Modal
                className="wallet-alert"
                title={
                  <>
                    <img src={AlertIcon} alt="Alert icon" />
                    <h3>Wallet Not Added!</h3>
                  </>
                }
                visible={isVisible}
                centered
                okText="Done"
                onOk={handleOk}
                cancelText="Close"
                onCancel={handleCancel}>
                <p>{modalText}</p>
              </Modal>
            </div>
          </div>
        </Form>
      ) : (
        <div className="wallet-info">
          <h4>Wallet Address</h4>
          <Input style={{ marginTop: "50px" }} value={user?.ethAddress} disabled />
        </div>
      )}
    </div>
  );
};

export default AddYourWallet;
