/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import Store from './store';
import {createBrowserHistory} from 'history';
import ReactGA from 'react-ga';
import './index.scss';
// redux
import {Provider} from 'react-redux';
import store from './revamp/context';

const browserHistory = createBrowserHistory ();
ReactGA.initialize (process.env.REACT_APP_GA);
browserHistory.listen ((location, action) => {
  ReactGA.pageview (location.pathname + location.search);
  console.log (location.pathname);
});

ReactDOM.render (
  <React.StrictMode>
    <BrowserRouter>
      <Store>
        <Provider store={store}>
          <App />
        </Provider>
      </Store>
    </BrowserRouter>,
  </React.StrictMode>,
  document.getElementById ('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals ();
