// modules & hooks
import React from "react";
import useWindowSize from "../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../constants";
// children
import { KYBForm } from "../components/KYBComponents";
// styles
import "../styles/PagesStyles/KybStyles/index.scss";

const KybPage = () => {
  // window size
  const windowSize = useWindowSize();

  return (
    <div
      className={`kyb ${
        windowSize.width < MEDIA_QUERIES.MD ? "responsive-md container" : "container-fluid"
      }`}>
      <KYBForm />
    </div>
  );
};

export default KybPage;
