// kyc
export const SET_KYC = 'SET_KYC';
export const GET_KYC = 'GET_KYC';

// kyb
export const SUBMIT_KYB_STEP_ONE = 'SUBMIT_STEP_ONE';
export const SUBMIT_KYB_STEP_TWO = 'SUBMIT_STEP_TWO';
export const SAVE_BENEFICIAL_ENTITY = 'ADD_BENEFICIAL_ENTITY';
export const SAVE_BENEFICIAL_OWNER = 'SAVE_BENEFICIAL_OWNER';
export const SUBMIT_KYB_STEP_THREE = 'SUBMIT_STEP_THREE';
export const SUBMIT_KYB_STEP_FOUR = 'SUBMIT_STEP_FOUR';

export const SUBMIT_KYB = 'SUBMIT_KYB';
export const GET_KYB = 'GET_KYB';

// shared
export const DASHBOARD_ACTIONS_MODAL = 'DASHBOARD_ACTIONS_MODAL';
export const HEADER_MAENU_MODAL = 'HEADER_MAENU_MODAL';
export const TOGGLE_TRANSACTIONS_APPLY_FILTER =
  'TOGGLE_TRANSACTIONS_APPLY_FILTER';
export const TOGGLE_PASSWORD_RULES = 'TOGGLE_PASSWORD_RULES';

export const SET_CURRENT_NAV = 'SET_CURRENT_NAV';
export const SET_ACCOUNT_TYPE = 'SET_ACCOUNT_TYPE';
