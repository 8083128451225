import React, {useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Layout from 'antd/lib/layout';

// import DashboardPage from "../DashboardPage";
import Header from '../../revamp/widgets/Header';
import RevampLayout from '../../revamp/widgets/Layout';

// import Header from '../../components/Header';
import WirePaymentPage from '../WirePaymentPage';
import WithdrawPage from '../WithdrawPage';
// import ProfilePage from '../ProfilePage';
import BankDetailsPage from '../BankDetailsPage';
import CardPayment from '../CardPayment';
import Anchor from 'antd/lib/anchor';

function MainTemplate () {
  useEffect (() => {
    window.scrollTo (0, 0);
  });
  return (
    <Anchor targetOffset={1000}>
      <Layout>
        <RevampLayout />
        {/* <Header status={status} />
        <main>
          <Switch>
            <Route path="/main/wire-payment" component={WirePaymentPage} />
            <Route path="/main/withdraw" component={WithdrawPage} />
            <Route path="/main/bank-details" component={BankDetailsPage} />
            <Route path="/main/card-payment" component={CardPayment} />
            <Route
              path="*"
              component={() => <Redirect to="/main/dashboard" />}
            />
          </Switch>
        </main> */}
      </Layout>
    </Anchor>
  );
}

export default MainTemplate;
