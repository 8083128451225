// modules & hooks
import React from "react";
import { MEDIA_QUERIES } from "../../../constants";
import useWindowSize from "../../../hooks/useWindowSize";
// components
import CoinItem from "./CoinItem";

const AllCoins = () => {
  // window size
  const windowSize = useWindowSize();

  return (
    <div className="coins">
      <div className={`${windowSize.width < MEDIA_QUERIES.MD ? "responsive-md" : ""}`}>
        <h4>All Coins</h4>
        <div className="coins__grid">
          <div className="coins__values">
            <CoinItem />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCoins;
