/* eslint-disable no-unused-vars */
// modules
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
// components
import { AllCoins } from "../components/DashboardComponents";
import AddYourWallet from "../components/DashboardComponents/AddYourWallet";
import USDWallet from "../components/DashboardComponents/USDWallet";
// styles
import "../shared/styles/index.scss";
import "../styles/PagesStyles/DashboardStyles/index.scss";

// services
import { getUser } from "../../services/users";
import { getOwnerSettings } from "../../services/general";
import { Context } from "../../store";
import useWindowSize from "../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../constants";
import DashboardActionsModal from "../UI/DashboardActionsModal";

const DashboardPage = () => {
  // states
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useContext(Context);
  const [user, setUser] = useState({});
  const [status, setStatus] = useState("");

  // fetching the data
  const fetchData = async () => {
    setLoading(true);
    const data = await getUser();
    const response = await getOwnerSettings();
    setUser(data.data ? data.data : {});
    setStatus(data.data.status ? data.data.status : null);
    dispatch({ type: "SET_OWNER_SETTINGS", payload: response.data });
    setLoading(false);
  };

  // use effect
  useEffect(() => {
    fetchData();
  }, []);

  console.log("user", user);

  // redux
  const isDashboardActionsModal = useSelector((state) => state.shared.ui.isDashboardActionsModal);

  // window size
  const windowSize = useWindowSize();

  return (
    <section
      className={`dashboard ${
        windowSize.width < MEDIA_QUERIES.MD ? "responsive-md container" : "container-fluid"
      }`}>
      <div
        className={`dashboard-top ${windowSize.width < MEDIA_QUERIES.MD ? "responsive-md" : null}`}>
        <div className="mainContent">
          <AddYourWallet user={user} />
          <USDWallet />
        </div>
      </div>
      <div className="dashboard-bottom">
        <AllCoins />
      </div>
      {isDashboardActionsModal ? <DashboardActionsModal /> : null}
    </section>
  );
};

export default DashboardPage;
