// modules
import React, { useState } from "react";
import { KYB_STEPS } from "../../../constants";
// children
import AdditionalInfo from "./AdditionalInfo";
// antd
import { Button, Form, Modal } from "antd";
// assets
import SuccessIcon from "../../../assets/img/popup/success-icon.png";
import { useDispatch } from "react-redux";
import { submitKyb, submitKybStepFour } from "../../../context/actions";
import { useSelector } from "react-redux";

const StepFour = ({ nextStepHandler }) => {
  // popup states and methods
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalText, setModalText] = useState(
    "You KYB has been submitted successfully. Please allow us 48 hours verify your submission."
  );
  const [isFinished, setIsFinished] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  // dispatcher
  const dispatcher = useDispatch();

  const handleOk = () => {
    setIsModalVisible(false);
    setIsFinished(true);
    // back to the first step (read only kyb mode)
    nextStepHandler(KYB_STEPS.ONE);
  };

  // antd form states and methods
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values of form:", values);
    dispatcher(submitKybStepFour(values));
    // Since it's the last step, the submitKyb action should be dispatched
    dispatcher(submitKyb());
  };

  // selectors
  const kybData = useSelector((state) => state.kyb);

  console.log({ finalKybData: kybData });

  return (
    <div className="kyb-step-four">
      <Form form={form} onFinish={onFinish}>
        <AdditionalInfo />

        <div className="kyb-step-four-note">
          Note: Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis eos minus fugiat
          esse doloribus, modi soluta repellat suscipit libero architecto.
        </div>
        <div className="kyb-submit">
          <Button className="back-btn" onClick={() => nextStepHandler(KYB_STEPS.THREE)}>
            Back
          </Button>
          <Button type="primary" htmlType="submit" onClick={showModal}>
            Submit
          </Button>
          <Modal
            className="register-success"
            title={
              <>
                <img src={SuccessIcon} alt="Success icon" />
                <span>Congratulations!</span>
              </>
            }
            visible={isModalVisible}
            centered
            okText="Done"
            onOk={handleOk}
            confirmLoading={confirmLoading}>
            <p>{modalText}</p>
          </Modal>
        </div>
      </Form>
    </div>
  );
};

export default StepFour;
