// modules
import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../../../constants";
// antd
import { Select } from "antd";
// components
import ShowcaseProcesses from "./ShowcaseProcesses";

const HomeShowcase = () => {
  // antd select methods
  const { Option } = Select;

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  // window size
  const windowSize = useWindowSize();

  return (
    <div className={`showcase ${windowSize.width < MEDIA_QUERIES.MD ? "responsive-md" : null}`}>
      <div className="showcase__header">
        <h4>Select Currency</h4>
        <div className="showcase__select">
          <Select defaultValue="EURST" style={{ width: 200 }} onChange={handleChange}>
            <Option value="jack">ABCD</Option>
            <Option value="lucy">EURST</Option>
          </Select>
        </div>
      </div>
      <ShowcaseProcesses />
    </div>
  );
};

export default HomeShowcase;
