/* eslint-disable react/display-name */
// modules & hooks
import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../../../constants";
// children
import TransactionsHeader from "./TransactionsHeader";
// antd
import { Table, Tag } from "antd";
import TransactionsApplyFilter from "../../../UI/TransactionsApplyFilter";
import { useSelector } from "react-redux";

// antd table columns
const columns = [
  {
    title: "S.No.",
    dataIndex: "no",
    key: "no",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Token",
    dataIndex: "token",
    key: "token",
  },
  {
    title: "USD Payment",
    dataIndex: "usd_payment",
    key: "usd_payment",
  },
  {
    title: "Payment Method",
    dataIndex: "payment_method",
    key: "payment_method",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (statuses) => (
      <>
        {statuses.map((status) => {
          let color = status.length > 5 ? "geekblue" : "green";
          if (status === "Approved") {
            color = "green";
          }
          if (status === "Declined") {
            color = "volcano";
          }
          if (status === "Pending") {
            color = "yellow";
          }
          return (
            <Tag color={color} key={status}>
              {status}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: "Reference",
    dataIndex: "reference",
    key: "reference",
  },
  {
    title: "Fee",
    dataIndex: "fee",
    key: "fee",
  },
];
// antd table data
const data = [
  {
    no: "1",
    id: "11",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Approved"],
    reference: "-",
    fee: 3.45,
  },
  {
    no: "2",
    id: "22",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Pending"],
    reference: "-",
    fee: 3.45,
  },
  {
    no: "13",
    id: "33",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Approved"],
    reference: "-",
    fee: 3.45,
  },
  {
    no: "4",
    id: "48",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Declined"],
    reference: "-",
    fee: 3.45,
  },
  {
    no: "5",
    id: "89",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Declined"],
    reference: "-",
    fee: 3.45,
  },
  {
    no: "6",
    id: "86",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Pending"],
    reference: "-",
    fee: 3.45,
  },
  {
    no: "7",
    id: "90",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Approved"],
    reference: "-",
    fee: 3.45,
  },
];

const HomeTransactions = () => {
  // redux
  const isTransactionsApplyFilter = useSelector(
    (state) => state.shared.ui.isTransactionsApplyFilter
  );
  console.log({ isTransactionsApplyFilter });

  // window size
  const windowSize = useWindowSize();

  return (
    <div
      className={`recent__transactions__list scrollable ${
        windowSize.width > MEDIA_QUERIES.MD ? "" : "responsive-md"
      }`}>
      <TransactionsHeader />
      <Table columns={columns} dataSource={data} />
      {isTransactionsApplyFilter ? <TransactionsApplyFilter /> : null}
    </div>
  );
};

export default HomeTransactions;
