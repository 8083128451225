// action types
// action types
import * as actionTypes from './actionTypes';

/* action creators */

// Kyc actions
export const setKyc = () => async dispatch => {
  try {
    const data = null;
    // TODO: Some api request to get the above payload data
    dispatch ({
      type: actionTypes.SET_KYC,
      payload: data,
    });
  } catch (error) {
    console.log (error);
  }
};

export const getKyc = () => async dispatch => {
  try {
    const data = null;
    // TODO: Some api request to get the above payload data
    dispatch ({
      type: actionTypes.SET_KYC,
      payload: data,
    });
  } catch (error) {
    console.log (error);
  }
};

// kyb actions
export const submitKybStepOne = stepOneData => {
  return {
    type: actionTypes.SUBMIT_KYB_STEP_ONE,
    payload: stepOneData,
  };
};

export const submitKybStepTwo = stepTwoData => {
  return {
    type: actionTypes.SUBMIT_KYB_STEP_TWO,
    payload: stepTwoData,
  };
};

// INTEGRATION NOTE: THIS ACTION MAY NEED TO MAKE SOME POST REQUEST
export const saveBeneficialEntity = beneficailEntityData => {
  return {
    type: actionTypes.SAVE_BENEFICIAL_ENTITY,
    payload: beneficailEntityData,
  };
};

export const saveBeneficialOwner = beneficailOwnerData => {
  return {
    type: actionTypes.SAVE_BENEFICIAL_OWNER,
    payload: beneficailOwnerData,
  };
};

export const submitKybStepThree = stepThreeData => {
  return {
    type: actionTypes.SUBMIT_KYB_STEP_THREE,
    payload: stepThreeData,
  };
};

export const submitKybStepFour = stepFourData => {
  return {
    type: actionTypes.SUBMIT_KYB_STEP_FOUR,
    payload: stepFourData,
  };
};

// INTEGRATION NOTE: THIS ACTION MAY NEED TO MAKE SOME POST REQUEST
export const submitKyb = () => async dispatch => {
  try {
    // TODO
    // const data = await ('some api');
    dispatch ({
      type: actionTypes.SUBMIT_KYB,
    });
  } catch (error) {
    console.log (error);
  }
};

// INTEGRATION NOTE: THIS ACTION MAY NEED TO MAKE SOME POST REQUEST
export const getKyb = () => {
  return {
    type: actionTypes.GET_KYB,
  };
};

// shared actions
export const toggleTransactionsApplyFilter = () => {
  return {
    type: actionTypes.TOGGLE_TRANSACTIONS_APPLY_FILTER,
  };
};

export const toggleHeaderMenuModal = () => {
  return {
    type: actionTypes.HEADER_MAENU_MODAL,
  };
};

export const toggleDashboardActionsModal = () => {
  return {
    type: actionTypes.DASHBOARD_ACTIONS_MODAL,
  };
};

export const setCurrentNav = path => {
  return {
    type: actionTypes.SET_CURRENT_NAV,
    payload: path,
  };
};

export const togglePasswordRules = () => {
  return {
    type: actionTypes.TOGGLE_PASSWORD_RULES,
  };
};

// INTEGRATION NOTE: THIS ACTION MAY NEED TO MAKE SOME GET REQUEST | REGISTER PAGE INTEGRATION
export const setAccountType = type => {
  return {
    type: actionTypes.SET_ACCOUNT_TYPE,
    payload: type,
  };
};
