/* eslint-disable */
// modules
import React, { useContext, useState } from "react";
import { Link, NavLink, useHistory, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

// antd
import { BarChartOutlined, PieChartFilled } from "@ant-design/icons";

// assets
import WallexLogo from "../assets/img/sidebar/logo.png";
import WithdrawIcon from "../assets/img/sidebar/homepage.png";
import TransactionsIcon from "../assets/img/sidebar/transactions.png";
import AvatarIcon from "../assets/img/sidebar/avatar.png";
import OptionsIcon from "../assets/img/sidebar/options.png";
import LogoutImage from "../assets/img/popup/logout-icon.png";

import DashoardAtiveIcon from "../assets/img/header/dashboard-active.png";
// import HomeIcon from "../assets/img/header/home-mobile-icon.png";
import HomeActiveIcon from "../assets/img/header/home-mobile-active.png";
import TransactionsActiveIcon from "../assets/img/header/transactions-mobile-active.png";
import TransferActiveIcon from "../assets/img/header/transfer-mobile-active.png";
import KybActiveIcon from "../assets/img/header/kyb-mobile-active.png";

// ant
import "antd/dist/antd.css";
// import "./index.css";
import { Avatar, Button, Layout, Menu, Modal } from "antd";
import LogoutIcon from "../assets/img/sidebar/logout.png";
// styles
import "../styles/WidgetsStyles/SidebarStyles.scss";

// services
import { Context } from "../../store";
import auth from "../../services/auth";
import { USER_TYPE, USER_STATUSES_AS_OBJECT } from "eurst-shared/src/enums";
import { useSelector } from "react-redux";
import { ACCOUNT_TYPES } from "../constants";

const { Sider } = Layout;

const menu = () => {
  return (
    <Menu>
      <Menu.Item key="profile">
        <a href="/main/profile">Profile</a>
      </Menu.Item>

      <Menu.Item key="logout">
        <a href="/logout">Logout</a>
      </Menu.Item>
    </Menu>
  );
};

function Sidebar(props) {
  // states
  const [viewProfile, setViewProfile] = useState(false);
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const { user } = state;
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  // selectors
  const accountType = useSelector((state) => state.shared.accountType);

  const modalText = "Are you sure you want to logout?";

  const showModal = () => {
    setVisible(true);
  };

  console.log("EEEEREERERER", user);

  const disbaleFields =
    [
      USER_STATUSES_AS_OBJECT.kycRequested,
      USER_STATUSES_AS_OBJECT.kycApproved,
      USER_STATUSES_AS_OBJECT.kycVerification,
    ].includes(user?.status) && user?.ethAddress;

  const handleClick = (e) => {
    dispatch({ type: "SET_CURRENT_NAV", payload: e.key });
  };

  // logout popup methods
  const handleOk = () => {
    setConfirmLoading(true);
    auth.logout();
    setConfirmLoading(false);
    setVisible(false);
    history.push("/");
    window.location.reload();
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  // view profile modal methods
  const profileNavHandler = () => {
    history.push("/main/profile");
    toggleViewProfileModal();
  };

  const toggleViewProfileModal = () => {
    setViewProfile((ps) => !ps);
  };

  console.log(history.location.pathname);
  let currentNav = null;

  const {
    location: { pathname },
  } = props;
  if (pathname.includes("dashboard")) {
    currentNav = "dashboard";
  } else if (pathname.includes("transactions")) {
    currentNav = "transactions";
  } else if (pathname.includes("home")) {
    currentNav = "home";
  } else if (pathname.includes("withdraw")) {
    currentNav = "withdraw";
  } else if (pathname.includes("profile")) {
    currentNav = "profile";
  } else {
    currentNav = "";
  }

  return (
    <div className="sidebar">
      <Layout hasSider />
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}>
        <div className="logo">
          <Link to="/main/dashboard">
            <img src={WallexLogo} alt="logo" />
          </Link>
        </div>
        <h5>Menu</h5>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["4"]}>
          <li className="sidebar-link">
            <NavLink to="/main/dashboard" activeClassName="active-link">
              <img src={DashoardAtiveIcon} alt="Dashboard active icon" />
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li className="sidebar-link">
            <NavLink to="/main/home" activeClassName="active-link">
              <img src={HomeActiveIcon} alt="Dashboard active icon" />
              <span>Mint &amp; Redeem</span>
            </NavLink>
          </li>
          <li className="sidebar-link">
            <NavLink to="/main/withdraw" activeClassName="active-link">
              <img src={TransferActiveIcon} alt="Dashboard active icon" />
              <span>Transfer</span>
            </NavLink>
          </li>
          <li className="sidebar-link">
            <NavLink to="/main/transactions" activeClassName="active-link">
              <img src={TransactionsActiveIcon} alt="Dashboard active icon" />
              <span>Transactions</span>
            </NavLink>
          </li>
          {accountType === ACCOUNT_TYPES.private ? (
            <li className="sidebar-link">
              <NavLink to="/main/kyc" activeClassName="active-link">
                <img src={KybActiveIcon} alt="Dashboard active icon" />
                <span>KYC</span>
              </NavLink>
            </li>
          ) : (
            <li className="sidebar-link">
              <NavLink to="/main/kyb" activeClassName="active-link">
                <img src={KybActiveIcon} alt="Dashboard active icon" />
                <span>KYB</span>
              </NavLink>
            </li>
          )}
        </Menu>
        <div className="sidebar-userInfo">
          <h4>Profile</h4>
          <div className={`sidebar-userInfo-content ${currentNav === "profile" ? "active" : null}`}>
            <div className="user__avatar">
              <img src={AvatarIcon} alt="User name" />
            </div>
            <div className="userData">
              <h5>{user?.userName}</h5>
              <p>{user?.email}</p>
            </div>
            <div className="userInfo-options">
              <Button type="primary" onClick={toggleViewProfileModal}>
                <img src={OptionsIcon} alt="Options icon" />
              </Button>
              <Modal
                className="view-profile-modal"
                visible={viewProfile}
                onOk={profileNavHandler}
                footer={[
                  <Button key="link" onClick={profileNavHandler}>
                    View Profile
                  </Button>,
                ]}
                onCancel={profileNavHandler}
                style={{
                  position: "absolute",
                  left: 200,
                  top: 620,
                }}
                width="fit-content"></Modal>
            </div>
          </div>
        </div>
        <div className="sidebar-logout">
          <Button
            type="primary"
            icon={<img src={LogoutIcon} alt="Logout icon" />}
            onClick={showModal}>
            Logout
          </Button>
          <Modal
            title={
              <>
                <img src={LogoutImage} alt="Logout icon" />
                <span>Logout</span>
              </>
            }
            visible={visible}
            centered
            okText="Yes, Logout"
            cancelText="Cancel"
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}>
            <p>{modalText}</p>
          </Modal>
        </div>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 200 }} />
    </div>
  );
}

Sidebar.propTypes = {
  status: PropTypes.string,
};

export default withRouter(Sidebar);
