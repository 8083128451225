import React from "react";

const PasswordRules = ({ isConfirmPassword }) => {
  console.log({ isConfirmPassword });
  return (
    <div className={`password-rules ${isConfirmPassword ? "confirm-modal" : null}`}>
      <h4 className="container-heading">Password should contain</h4>
      <ul>
        <li>More than 8 characters</li>
        <li>Uppercase letters (A-Z)</li>
        <li>Lowercase letters (a-z)</li>
        <li>Digits (0-9)</li>
        <li>Special characters (!, $, #, %, etc.)</li>
      </ul>
    </div>
  );
};

export default PasswordRules;
