// modules
import React, { useState } from "react";
import { KYB_STEPS } from "../../../constants";
// children
import AddBeneficialEntity from "./AddBeneficialEntity";
// antd
import { Avatar, Button, Form, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { submitKybStepThree } from "../../../context/actions";
// assets
import UpArrow from "../../../assets/img/kyc/up-arrow.png";
import DownArrow from "../../../assets/img/kyc/down-arrow.png";
import BeneficialOwnershipModal from "../../../UI/BeneficialOwnershipModal";
import AddBeneficialOwner from "./AddBeneficialOwner";

const StepThree = ({ nextStepHandler }) => {
  // selectors
  const kybData = useSelector((state) => state.kyb.step3);
  // local states
  const [isAddBeneficialOwner, setIsAddBeneficialOwner] = useState(false);
  const [isAddBeneficialEntity, setIsAddBeneficialEntity] = useState(false);
  const [isExpandedEntityContent, setIsExpandedEntityContent] = useState(false);
  const [isExpandedOwnerContent, setIsExpandedOwnerContent] = useState(false);
  const [privacyVisible, setPrivacyVisible] = useState(false);

  // toggle expanded and contents
  const addBeneficialOwnerHandler = () => {
    setIsAddBeneficialOwner((ps) => !ps);
    !isExpandedOwnerContent && setIsExpandedOwnerContent(true);
    // isAddBeneficialEntity && setIsAddBeneficialEntity(true);
    isExpandedEntityContent && setIsExpandedEntityContent(false);
  };

  const addBeneficialEntityHandler = () => {
    setIsAddBeneficialEntity((ps) => !ps);
    // isAddBeneficialOwner && setIsAddBeneficialOwner(true);
    if (!isExpandedEntityContent) setIsExpandedEntityContent(true);
    isExpandedOwnerContent && setIsExpandedOwnerContent(false);
  };

  const toggleExpandedEntityContent = () => setIsExpandedEntityContent((ps) => !ps);
  const toggleExpandedOwnerContent = () => setIsExpandedOwnerContent((ps) => !ps);

  const showPrivacyPolicyModal = () => {
    setPrivacyVisible(true);
  };

  const handleOkPrivacy = () => {
    setPrivacyVisible(false);
  };

  const handleCancelPrivacy = () => {
    setPrivacyVisible(false);
  };

  // dispatcher
  const dispatcher = useDispatch();

  // antd form states and methods
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values of form:", values);
    dispatcher(submitKybStepThree(values));
    nextStepHandler(KYB_STEPS.FOUR);
  };

  return (
    <div className="kyb-step-three">
      <div className="kyb-owners-content">
        <div className="kyb-owners-intro">
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam dolore expedita
            excepturi, voluptatem, tempora ratione placeat saepe veritatis molestiae reprehenderit
            ab obcaecati temporibus repellendus voluptatibus esse? Officia exercitationem cumque
            molestias praesentium nisi ullam, nam nobis dolores consequuntur facilis cum aspernatur
            ratione quibusdam ex! Et aliquid ut sint. Deserunt, quis suscipit.
          </p>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti nam iste velit
            aliquam, fuga expedita magnam praesentium ratione veniam cum.
          </p>
          <Button type="primary" onClick={showPrivacyPolicyModal} className="link">
            Beneficial Owner Attestation
          </Button>
          <Modal
            className="beneficial-ownership-modal"
            visible={privacyVisible}
            centered
            okText="Done"
            onOk={handleOkPrivacy}
            cancelText="Close"
            onCancel={handleCancelPrivacy}
            footer={[
              <div key="kyb-modal-options" className="kyb-modal-options">
                <Button className="kyb-modal-btn" key="ok" type="primary" onClick={handleOkPrivacy}>
                  Download Beneficial Ownership Attestation
                </Button>
                <Button className="kyb-modal-btn" key="cancel" onClick={handleCancelPrivacy}>
                  Close
                </Button>
              </div>,
            ]}>
            <BeneficialOwnershipModal handleCancelPrivacy={handleCancelPrivacy} />
          </Modal>
        </div>
        <div className="kyb-adding-owner">
          <p>If any entity owns 25% or more, please add them.</p>
          <Button onClick={addBeneficialOwnerHandler}>
            Add Beneficial Owner (Individual) or Contact Person
          </Button>
          <Button onClick={addBeneficialEntityHandler}>Add Beneficial Entity</Button>
        </div>
        <div className="kyb-owners-note">
          Note: Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto possimus eos officia,
          omnis quae voluptatum!
        </div>
        {/** If the isAddBeneficialEntity is true, the AddBeneficialEntity component will be displayed  */}
        {isAddBeneficialEntity ? (
          <AddBeneficialEntity
            isExpandedEntityContent={isExpandedEntityContent}
            toggleExpandedEntityContent={toggleExpandedEntityContent}
          />
        ) : null}

        {/** If the isAddBeneficialOwner is true, the AddBeneficialOwner component will be displayed  */}
        {isAddBeneficialOwner ? (
          <AddBeneficialOwner
            isExpandedOwnerContent={isExpandedOwnerContent}
            toggleExpandedOwnerContent={toggleExpandedOwnerContent}
          />
        ) : null}
      </div>
      <Form form={form} onFinish={onFinish}>
        <div className="kyb-submit">
          <Button className="back-btn" onClick={() => nextStepHandler(KYB_STEPS.TWO)}>
            Back
          </Button>
          <Button type="primary" onClick={() => nextStepHandler(KYB_STEPS.FOUR)}>
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default StepThree;
