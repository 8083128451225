// modules
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
// reducers
import {kycReducer, kybReducer, sharedReducer} from './reducers';

const rootReducer = combineReducers ({
  main: kycReducer,
  kyb: kybReducer,
  shared: sharedReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore (
  rootReducer,
  composeEnhancers (applyMiddleware (compose (thunk)))
);

// exports
export default store;
