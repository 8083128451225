// modules & hooks
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Form, Input, Select } from "antd";
import useWindowSize from "../../../../hooks/useWindowSize";

const BeneficialEntityAddress = () => {
  // states
  const isKybSubmitted = useSelector((state) => state.kyb.isKybSubmitted);

  // antd select methods
  const { Option } = Select;
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  // selectors
  const kybData = useSelector((state) => state.kyb);

  const [formData, setFormData] = useState(kybData.step2.entityAddress);

  useEffect(() => {
    setFormData(kybData.step2);
  }, []);

  console.log({ kybData });

  // window size
  const windowSize = useWindowSize();

  return (
    <div className="kyb-entity-address">
      <h3 className="container-heading">Entity Address</h3>

      <Col>
        <Form.Item
          style={{ display: "flex", flexDirection: "column" }}
          name="countryOfResidence"
          label="Country of Residence"
          rules={[
            {
              required: true,
            },
          ]}>
          <Select
            defaultValue={formData ? formData?.entityAddressCountry : "India"}
            onChange={handleChange}
            disabled={isKybSubmitted}>
            <Option value="India">India</Option>
            <Option value="Egypt">Egypt</Option>
          </Select>
        </Form.Item>
      </Col>

      <Col>
        <Form.Item
          name="streetAddress"
          label="Street Address"
          rules={[
            {
              required: true,
              message: "Street Address is required!",
            },
            {
              max: 100,
              message: "Street Address should be less than 100 symbols!",
              whitespace: true,
            },
          ]}>
          <Input
            placeholder="Enter Address"
            defaultValue={formData?.streetAddress}
            disabled={isKybSubmitted}
          />
        </Form.Item>
      </Col>

      <Col>
        <Form.Item
          name="streetAddress2"
          label="Street Address 2 (Apt, Suite, etc.)"
          rules={[
            {
              required: true,
              message: "Street Address is required!",
            },
            {
              max: 100,
              message: "Second Street Address should be less than 100 symbols!",
              whitespace: true,
            },
          ]}>
          <Input
            placeholder="Enter Address 2"
            defaultValue={formData?.streetAddress2}
            disabled={isKybSubmitted}
          />
        </Form.Item>
      </Col>

      <Col>
        <Form.Item
          name="city"
          label="City"
          rules={[
            {
              required: true,
              message: "City is required!",
            },
            {
              max: 25,
              message: "City should be less than 25 symbols!",
              whitespace: true,
            },
          ]}>
          <Input placeholder="Enter City" defaultValue={formData?.city} disabled={isKybSubmitted} />
        </Form.Item>
      </Col>

      <Col>
        <Form.Item
          name="region"
          label="Region"
          rules={[
            {
              required: true,
              message: "Region is required!",
            },
            {
              max: 25,
              message: "Region should be less than 25 symbols!",
              whitespace: true,
            },
          ]}>
          <Input
            placeholder="Enter Region"
            defaultValue={formData?.region}
            disabled={isKybSubmitted}
          />
        </Form.Item>
      </Col>

      <Col>
        <Form.Item
          name="zip"
          label="Postal Code"
          rules={[
            {
              required: true,
              message: "Postal code is required!",
            },
            {
              pattern: /^\d+$/,
              message: "Postal code is not valid!",
              whitespace: true,
            },
          ]}>
          <Input
            placeholder="Enter Postal Code"
            defaultValue={formData?.zip}
            disabled={isKybSubmitted}
          />
        </Form.Item>
      </Col>
    </div>
  );
};

export default BeneficialEntityAddress;
