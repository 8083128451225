// modules
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// antd
import { Button, Modal } from "antd";
import { MenuOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
// assets
import JohnImg from "../assets/img/header/john.png";
import LogoutIcon from "../assets/img/header/logout.png";
import LogoutImage from "../assets/img/popup/logout-icon.png";
// services
import auth from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { toggleHeaderMenuModal } from "../context/actions";

const HeaderMenu = () => {
  // dispatcher
  const dispatcher = useDispatch();
  // redux
  const isHeaderMenuModal = useSelector((state) => state.shared.ui.isHeaderMenuModal);

  // toggle menu modal
  const toggleMenuModal = (e) => {
    e.stopPropagation();
    dispatcher(toggleHeaderMenuModal());
  };

  const modalText = "Are you sure you want to logout?";
  // logout popup methods
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const showModal = () => {
    setVisible(true);
    dispatcher(toggleHeaderMenuModal());
  };

  console.log({ visible });

  const history = useHistory();

  const handleOk = () => {
    setConfirmLoading(true);
    auth.logout();
    setConfirmLoading(false);
    setVisible(false);
    history.push("/");
    window.location.reload();
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };
  return (
    <div className="header-menu">
      <Button onClick={toggleMenuModal}>
        <MenuOutlined />
      </Button>
      {isHeaderMenuModal ? (
        <div className="menu-modal">
          <Link className="link" to="/main/profile" onClick={toggleMenuModal}>
            <div className="text">
              <img src={JohnImg} alt="Username" className="profile-pic" />
              <span>John Deo</span>
            </div>
            <RightOutlined />
          </Link>
          <div className="link" onClick={showModal}>
            <div className="text">
              <img src={LogoutIcon} alt="Logout icon" />
              <Button
                onClick={showModal}
                style={{
                  fontWeight: 600,
                  background: "transparent",
                  border: "none",
                }}>
                Logout
              </Button>
            </div>
            <RightOutlined />
          </div>
        </div>
      ) : null}
      <Modal
        title={
          <>
            <img src={LogoutImage} alt="Logout icon" />
            <span>Logout</span>
          </>
        }
        visible={visible}
        centered
        okText="Yes, Logout"
        cancelText="Cancel"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}>
        <p>{modalText}</p>
      </Modal>
    </div>
  );
};

export default HeaderMenu;
