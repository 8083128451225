// modules & hooks
import React from "react";
import { MEDIA_QUERIES } from "../constants";
import useWindowSize from "../hooks/useWindowSize";
// components
import KYCForm from "../components/KYCComponents/KYCForm";
// styles
import "../styles/PagesStyles/KYCStyles/index.scss";

const KYCPage = () => {
  // window size
  const windowSize = useWindowSize();

  return (
    <section
      className={`kyc ${
        windowSize.width > MEDIA_QUERIES.MD ? "container-fluid" : "responsive-md container"
      }`}>
      <KYCForm />
    </section>
  );
};

export default KYCPage;
