// modules & hooks
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { KYB_STEPS, MEDIA_QUERIES } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";
// children
import KYBStepOne from "./Step1";
import KYBStepTwo from "./Step2";
import KYBStepThree from "./Step3";
import KYBStepFour from "./Step4";

const KYBForm = () => {
  const [step, setStep] = useState(KYB_STEPS.ONE);

  // next step handler
  const nextStepHandler = (step) => {
    setStep(step);
  };

  // selectors
  const kybData = useSelector((state) => state.kyb);

  // window size
  const windowSize = useWindowSize();

  console.log({ kybData });
  return (
    <div className="kyb-form">
      <div className="kyb-heading">
        <h2 className="container-heading">KYB</h2>
        <p>This step is necessary to get access to the full range of EURST operations.</p>
      </div>
      <div
        className={`steps-indicator ${windowSize.width < MEDIA_QUERIES.MD ? "responsive-md" : ""}`}>
        <div className={`${step === KYB_STEPS.ONE ? "active" : null}`}>
          <span className="step-num">1</span>
          {windowSize.width > MEDIA_QUERIES.MD ? <span>Account Name</span> : null}
        </div>
        <div className={`${step === KYB_STEPS.TWO ? "active" : null}`}>
          <span className="step-num">2</span>
          {windowSize.width > MEDIA_QUERIES.MD ? <span>Entity Information</span> : null}
        </div>
        <div className={`${step === KYB_STEPS.THREE ? "active" : null}`}>
          <span className="step-num">3</span>
          {windowSize.width > MEDIA_QUERIES.MD ? <span>Related Contacts</span> : null}
        </div>
        <div className={`${step === KYB_STEPS.FOUR ? "active" : null}`}>
          <span className="step-num">4</span>
          {windowSize.width > MEDIA_QUERIES.MD ? <span>Additional Information</span> : null}
        </div>
      </div>
      {step === KYB_STEPS.ONE ? <KYBStepOne nextStepHandler={nextStepHandler} /> : null}
      {step === KYB_STEPS.TWO ? <KYBStepTwo nextStepHandler={nextStepHandler} /> : null}
      {step === KYB_STEPS.THREE ? <KYBStepThree nextStepHandler={nextStepHandler} /> : null}
      {step === KYB_STEPS.FOUR ? <KYBStepFour nextStepHandler={nextStepHandler} /> : null}
    </div>
  );
};

export default KYBForm;
