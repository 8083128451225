// modules & hooks
import React, { useEffect, useState } from "react";
import moment from "moment";
import { MEDIA_QUERIES } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";
import { GENDER } from "../../../constants/constants";
// antd
import { Col, DatePicker, Form, Input, Row, Select, Space } from "antd";
// services
import { getCountries } from "../../../services/countries";

const PersonalDetails = ({ isFinished }) => {
  // antd
  const { Option } = Select;
  const [countries, setCountries] = useState([]);
  // window size
  const windowSize = useWindowSize();

  useEffect(() => {
    const fethInitialData = async () => {
      const data = await getCountries();
      setCountries(data);
    };
    fethInitialData();
  }, []);

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  const disabledDate = (current) => {
    const start = moment("1900-01-01", "YYYY-MM-DD");
    return current < start || current > moment();
  };
  const dateFormatList = ["YYYY/MM/DD", "YY/MM/DD"];

  return (
    <div className="kyc-personal-details kyc-form">
      <h3 className="container-heading">Personal Details</h3>
      <Row gutter={16}>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
              },
              {
                max: 80,
                message: "First Name should be less than 80 symbols!",
                whitespace: true,
              },
            ]}>
            <Input placeholder="First Name" disabled={isFinished} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              {
                required: true,
              },
              {
                max: 80,
                message: "Last Name should be less than 80 symbols!",
                whitespace: true,
              },
            ]}>
            <Input placeholder="Last Name" disabled={isFinished} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="dateOfBirth"
            label="Date Of Birth"
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const age = moment().diff(moment(value), "years");
                  if (age >= 18) {
                    return Promise.resolve();
                  }

                  return Promise.reject("You must be 18 years old or above!");
                },
              }),
            ]}>
            {/* <Space direction="vertical"> */}
            <DatePicker
              size="default"
              inputReadOnly
              placeholder={"YEAR/MM/DD"}
              disabledDate={disabledDate}
              format={dateFormatList}
            />
            {/* </Space> */}
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="gender"
            label="Gender"
            rules={[
              {
                required: true,
              },
            ]}>
            <Select onChange={handleChange} disabled={isFinished} placeholder="Gender">
              {Object.values(GENDER).map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="countryOfResidence"
            label="Country Of Residence"
            rules={[
              {
                required: true,
                message: "Please input your country of residence!",
              },
            ]}>
            <Select onChange={handleChange} disabled={isFinished} placeholder="Select a country">
              {countries
                .filter((item) => item.available)
                .map((item, index) => (
                  <Option key={index} value={item.code} disabled={!item.available}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="taxIdNumber"
            label="Tax ID Number"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input
              placeholder="Tax ID Number for US or ID # listed on uploaded proof id for Non US"
              disabled={isFinished}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="citizenship"
            label="Citizenship"
            rules={[
              {
                required: true,
              },
            ]}>
            <Select
              onChange={handleChange}
              disabled={isFinished}
              placeholder="Select a citizenship">
              {countries.map((item, index) => (
                <Option key={`citizenship-${index}`} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* {countryCode === "US" && (
                    <Form.Item
                      name="taxState"
                      label="Tax State"
                      hidden={countryCode !== "US"}
                      rules={[{ required: countryCode === "US" }]}>
                      <CustomSelect placeholder="Tax State">
                        {US_STATES.map((item, i) => {
                          return (
                            <Option key={`state-${i}`} value={item.code}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </CustomSelect>
                    </Form.Item>
                  )} */}
        </Col>
      </Row>
    </div>
  );
};

export default PersonalDetails;
