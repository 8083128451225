// modules & hooks
import React, { useState } from "react";
import { KYB_STEPS } from "../../../constants";
// children
import EntityContactInfo from "./EntityContactInfo";
// antd
import { Button, Form } from "antd";
import EntityAddress from "./EntityAddress";
import EntityDocVerification from "./EntityDocVerification";
import { useDispatch, useSelector } from "react-redux";
import { submitKybStepTwo } from "../../../context/actions";

const StepTwo = ({ nextStepHandler }) => {
  // dispatcher
  const dispatcher = useDispatch();

  // antd form states and methods
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values of form:", values);
    dispatcher(submitKybStepTwo(values));
    nextStepHandler(KYB_STEPS.THREE);
  };

  // selectors
  const kybData = useSelector((state) => state.kyb);

  console.log({ kybData });

  return (
    <div className="kyb-step-two" name="kyb_form_step_two" onFinish={onFinish} autoComplete="off">
      <Form form={form} onFinish={onFinish}>
        <EntityContactInfo stepNumber={KYB_STEPS.TWO} />
        <EntityAddress />
        <EntityDocVerification />

        <div className="kyb-submit">
          <Button className="back-btn" onClick={() => nextStepHandler(KYB_STEPS.ONE)}>
            Back
          </Button>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default StepTwo;
