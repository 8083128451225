// modules & hooks
import React, { useState } from "react";
import { useSelector } from "react-redux";
import useWindowSize from "../../../hooks/useWindowSize";
import { ACCOUNT_TYPES, MEDIA_QUERIES } from "../../../constants";
// assets
import SuccessIcon from "../../../assets/img/popup/success-icon.png";
// antd
import { Col, Form, Input, Row } from "antd";

const WalletInfo = ({ data }) => {
  // states
  const [isApproved, setIsApproved] = useState(true);

  // cheching account type
  const isBusinessAccount =
    useSelector((state) => state.shared.accountType) === ACCOUNT_TYPES.business;

  // window size
  const windowSize = useWindowSize();

  return (
    <div className="wallet-info infoBx">
      <div className="infoBx-heading">
        {isBusinessAccount ? (
          <h3 className="container-heading">KYB Status</h3>
        ) : (
          <h3 className="container-heading">KYC Status</h3>
        )}
        <p className="status">
          <img src={SuccessIcon} alt="Success icon" />
          <span>{isApproved ? "Approved" : null}</span>
        </p>
      </div>
      <div className="info__content">
        <Row gutter={16}>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Wallet"
              label="Wallet"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input value="-" placeholder="-" disabled={isApproved} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="EURST Balance"
              label="EURST Balance"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input type="text" value="-" placeholder="-" disabled={isApproved} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Wallet Address"
              label="Wallet Address"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input type="text" placeholder="tyuyiouoiopijuoiipo" disabled={isApproved} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WalletInfo;
