// modules
import { Avatar, Button, Form } from "antd";
import React from "react";
import BeneficialOwnerAddress from "./BeneficialOwnerAddress";
// children
import BeneficialOwnerContactInfo from "./BeneficialOwnerContactInfo";
import BeneficialOwnerDocVerification from "./BeneficialOwnerDocVerification";
// assets
import UpArrow from "../../../../assets/img/kyc/up-arrow.png";
import DownArrow from "../../../../assets/img/kyc/down-arrow.png";
import CompanyIcon from "../../../../assets/img/kyc/company-icon.png";
import { useDispatch } from "react-redux";
import { saveBeneficialOwner } from "../../../../context/actions";

const AddBeneficialOwner = ({ isExpandedOwnerContent, toggleExpandedOwnerContent }) => {
  // dispatcher
  const dispatcher = useDispatch();

  // antd form states and methods
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values of form:", values);
    dispatcher(saveBeneficialOwner(values));
  };

  return (
    <div className="kyb-control-persons">
      <div className="add-beneficial-entity-content">
        <div className="heading">
          <div className="user-info">
            <img src={CompanyIcon} alt="Company icon" />
            <h4 className="username">The Dotsoft Tech</h4>
          </div>
          <div className="toggle-icon">
            <img
              src={isExpandedOwnerContent ? UpArrow : DownArrow}
              alt="Up arrow"
              onClick={toggleExpandedOwnerContent}
            />
          </div>
        </div>
        {isExpandedOwnerContent ? (
          <Form form={form} onFinish={onFinish}>
            <BeneficialOwnerContactInfo />
            <BeneficialOwnerAddress />
            <BeneficialOwnerDocVerification />
            <div className="options">
              <Button className="save" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        ) : null}
      </div>
    </div>
  );
};

export default AddBeneficialOwner;
