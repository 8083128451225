// modules & hooks
import React, { useEffect, useState } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { KYB_STEPS, MEDIA_QUERIES } from "../../../constants";
// antd
import { Col, Form, Input, Row, Select } from "antd";
import { useSelector } from "react-redux";

const EntityContactInfo = () => {
  // selectors
  const isKybSubmitted = useSelector((state) => state.kyb.isKybSubmitted);

  // antd select's methods
  const { Option } = Select;
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  // selectors
  const kybData = useSelector((state) => state.kyb.step2.entityContactInfo);

  // form data is based on the redux store
  const [formData, setFormData] = useState(kybData);

  useEffect(() => {
    setFormData(kybData);
  }, []);

  // window size
  const windowSize = useWindowSize();

  return (
    <div className="kyb-contact-info">
      <h3 className="container-heading">Entity Contact Information</h3>
      <div className="kyb-second-step-note">
        <p>
          <b>Note:</b>
          <span>
            This form must be completed by the person opening a new account on behalf of a legal
            entity at the time each new account is opened.
          </span>
        </p>
      </div>

      <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
        <Form.Item
          name="entityName"
          label="Entity Name"
          rules={
            !isKybSubmitted && !kybData?.entityName
              ? [
                  {
                    required: true,
                  },
                ]
              : null
          }>
          <Input
            type="text"
            value={formData?.entityName}
            defaultValue={formData?.entityName}
            placeholder="John Doe"
            disabled={isKybSubmitted}
          />
        </Form.Item>
      </Col>

      <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
        <Form.Item
          name="entityContactEmail"
          label="Entity Contact Email"
          rules={
            !isKybSubmitted && !kybData?.entityContactEmail
              ? [
                  {
                    required: true,
                  },
                ]
              : null
          }>
          <Input
            type="text"
            value={formData?.entityContactEmail}
            defaultValue={formData?.entityContactEmail}
            placeholder="john@mail.com"
            disabled={isKybSubmitted}
          />
        </Form.Item>
      </Col>

      <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
        <Row gutter={16}>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={
              !isKybSubmitted && !kybData?.phoneNumber
                ? [
                    {
                      required: true,
                    },
                  ]
                : null
            }>
            <Row gutter={6}>
              <Col className="gutter-row" span={6}>
                <Select defaultValue="+91" onChange={handleChange} disabled={isKybSubmitted}>
                  <Option value="+91">+91</Option>
                  <Option value="+20">+20</Option>
                </Select>
              </Col>
              <Col className="gutter-row" span={18}>
                <Input
                  placeholder="123456789"
                  value={formData?.phoneNumber}
                  defaultValue={formData?.phoneNumber}
                  disabled={isKybSubmitted}
                />
              </Col>
            </Row>
          </Form.Item>
        </Row>
      </Col>
    </div>
  );
};

export default EntityContactInfo;
