// modules & hooks
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ACCOUNT_TYPES, MEDIA_QUERIES } from "../../../constants";
import useWindowSize from "../../../hooks/useWindowSize";
// antd
import { InfoCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import { Col, DatePicker, Option, Form, Input, Row, Select, Space } from "antd";
// assets
import SuccessIcon from "../../../assets/img/popup/success-icon.png";
import { useSelector } from "react-redux";

const BasicInfo = () => {
  // states
  const [isApproved, setIsApproved] = useState(true);

  // antd select methods
  const { Option } = Select;
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  // cheching account type
  const isBusinessAccount =
    useSelector((state) => state.shared.accountType) === ACCOUNT_TYPES.business;

  // window size
  const windowSize = useWindowSize();

  return (
    <div className="basic-info infoBx">
      <div className="infoBx-heading">
        {isBusinessAccount ? (
          <h3 className="container-heading">KYB Status</h3>
        ) : (
          <h3 className="container-heading">KYC Status</h3>
        )}
        <p className="status">
          <img src={SuccessIcon} alt="Success icon" />
          <span>{isApproved ? "Approved" : null}</span>
        </p>
      </div>
      <div className="info__warning">
        <InfoCircleFilled style={{ color: "#A88100" }} />
        <p>
          If you want to edit your profile details, please contact our{" "}
          <Link to="/">Support Team</Link>
        </p>
      </div>
      <div className="info__content">
        <Row gutter={16}>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Full Name"
              label="Full Name"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input placeholder="John Deo" disabled={isApproved} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Email Address"
              label="Email Address"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input type="email" placeholder="John_deo@mail.com" disabled={isApproved} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Date of birth"
              label="Date Of Birth"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Space direction="vertical">
                <DatePicker size="default" />
              </Space>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Gender"
              label="Gender"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select defaultValue="Male" onChange={handleChange} disabled={isApproved}>
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Row gutter={16}>
              <Form.Item
                name="Phone number"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Row gutter={6}>
                  <Col className="gutter-row" span={4}>
                    <Select defaultValue="+91" onChange={handleChange} disabled={isApproved}>
                      <Option value="+91">+91</Option>
                      <Option value="+20">+20</Option>
                    </Select>
                  </Col>
                  <Col className="gutter-row" span={20}>
                    <Input placeholder="123456789" disabled={isApproved} />
                  </Col>
                </Row>
              </Form.Item>
            </Row>
          </Col>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Country of residence"
              label="Country Of Residence"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select defaultValue="India" onChange={handleChange} disabled={isApproved}>
                <Option value="India">India</Option>
                <Option value="Egypt">Egypt</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item
            name="Full Address"
            label="Full Address"
            rules={[
              {
                required: true,
              },
            ]}>
            <Select defaultValue="India" onChange={handleChange} disabled={isApproved}>
              <Option value="India">Indian</Option>
              <Option value="Egypt">Egyptian</Option>
            </Select>
          </Form.Item>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Citizenship"
              label="Citizenship"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select defaultValue="India" onChange={handleChange} disabled={isApproved}>
                <Option value="India">Indian</Option>
                <Option value="Egypt">Egyptian</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BasicInfo;
