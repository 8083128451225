// modules & hooks
import React, { useEffect, useState } from "react";
import { MEDIA_QUERIES } from "../../../constants";
import useWindowSize from "../../../hooks/useWindowSize";
// antd
import { Col, Form, Input } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";

const AdditionalInfo = () => {
  // selectors
  const kybData = useSelector((state) => state.kyb.step4.additionalInformation);
  const isKybSubmitted = useSelector((state) => state.kyb.isKybSubmitted);

  // local states
  const [formData, setFormData] = useState(kybData);

  useEffect(() => {
    setFormData(kybData);
  }, []);

  console.log({ formData });

  // window size
  const windowSize = useWindowSize();

  return (
    <div className="kyb-additional-info">
      <h3 className="container-heading">AdditionalInfo</h3>

      <div className="additional-info">
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="purposeOfAccount"
            label="Purpose Of Account"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input
              type="text"
              defaultValue={formData?.purposeOfAccount}
              value={formData?.purposeOfAccount}
              placeholder="Enter purpose of account"
              disabled={isKybSubmitted}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="associationsWithOtherAccount"
            label="Associations with other account"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input
              type="text"
              defaultValue={formData?.associationsWithOtherAccount}
              value={formData?.associationsWithOtherAccount}
              placeholder="Enter purpose of account"
              disabled={isKybSubmitted}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="sourceOfAssets"
            label="Source of Assets and Income"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input
              type="text"
              defaultValue={formData?.sourceOfAssets}
              value={formData?.sourceOfAssets}
              placeholder="Enter"
              disabled={isKybSubmitted}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="intendedUseOfAccount"
            label="Intended use of Account"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input
              type="text"
              defaultValue={formData?.intendedUseOfAccount}
              value={formData?.intendedUseOfAccount}
              placeholder="Enter"
              disabled={isKybSubmitted}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="anticipatedTypesOfAssets"
            label="Anticipated Types of Assets"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input
              type="text"
              defaultValue={formData?.anticipatedTypesOfAssets}
              value={formData?.anticipatedTypesOfAssets}
              placeholder="Enter"
              disabled={isKybSubmitted}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="anticipatedMonthlyCashVolume"
            label="Anticipated Monthly Cash Volume"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input
              type="text"
              defaultValue={formData?.anticipatedMonthlyCashVolume}
              value={formData?.anticipatedMonthlyCashVolume}
              placeholder="Enter"
              disabled={isKybSubmitted}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="anticipatedTradingPatterns"
            label="Anticipated Trading Patterns"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input
              type="text"
              defaultValue={formData?.anticipatedTradingPatterns}
              value={formData?.anticipatedTradingPatterns}
              placeholder="Enter"
              disabled={isKybSubmitted}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="anticipatedIncomeMonthlyTransactions"
            label="Anticipated Income Monthly Transactions"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input
              type="text"
              defaultValue={formData?.anticipatedIncomeMonthlyTransactions}
              value={formData?.anticipatedIncomeMonthlyTransactions}
              placeholder="Enter"
              disabled={isKybSubmitted}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="anticipatedOutcomeMonthlyTransactions"
            label="Anticipated Outgoing Monthly Transactions"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input
              type="text"
              defaultValue={formData?.anticipatedOutcomeMonthlyTransactions}
              value={formData?.anticipatedOutcomeMonthlyTransactions}
              placeholder="Enter"
              disabled={isKybSubmitted}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="natureOfCompanyBusiness"
            label="Nature of the business of the Company"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input
              type="text"
              defaultValue={formData?.natureOfCompanyBusiness}
              value={formData?.natureOfCompanyBusiness}
              placeholder="Enter"
              disabled={isKybSubmitted}
            />
          </Form.Item>
        </Col>
      </div>

      <div className="account-agreement">
        {/** TODO: WARNING & AGREEMENT */}
        <h3 className="container-heading">Account Agreement</h3>
        <div className="info__warning">
          <InfoCircleFilled style={{ color: "#A88100" }} />
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde dolorem at corporis totam!
            Possimus, autem exercitationem dignissimos quidem ad cupiditate.
          </p>
        </div>

        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="fullName"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input
              type="text"
              defaultValue={formData?.fullName}
              value={formData?.fullName}
              placeholder="Enter your Full Name"
              disabled={isKybSubmitted}
            />
          </Form.Item>
        </Col>
      </div>
    </div>
  );
};

export default AdditionalInfo;
