// modules & hooks
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MEDIA_QUERIES } from "../../../constants";
import useWindowSize from "../../../hooks/useWindowSize";
// antd
import { Col, Form, Input } from "antd";

const AccountName = () => {
  // selectors
  const kybData = useSelector((state) => state.kyb.step1);
  const accountName = kybData?.accountName;
  const isKybSubmitted = useSelector((state) => state.kyb.isKybSubmitted);

  // states
  const [formData, setFormData] = useState(kybData);

  useEffect(() => {
    setFormData(kybData);
    console.log("STEP1 RENDERD");
  }, []);

  // window size
  const windowSize = useWindowSize();
  console.log({ accName: formData?.accountName });
  // console.log({ formData });

  return (
    <div className="kyb-account-name">
      <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
        <Form.Item
          name="accountName"
          label="Account Name"
          rules={
            !isKybSubmitted && !accountName
              ? [
                  {
                    required: true,
                    message: "Account name is required",
                  },
                ]
              : null
          }>
          <Input
            type="text"
            defaultValue={accountName ? accountName : ""}
            value={accountName ? accountName : ""}
            placeholder="John Doe's Account"
            disabled={isKybSubmitted}
          />
        </Form.Item>
      </Col>
      <div className="kyb-account-name-text">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id, molestiae vitae! Pariatur
          mollitia quae enim eligendi! Omnis velit eum pariatur iure quasi? Ipsam quis cumque fugiat
          itaque temporibus minus voluptas doloremque commodi illo! Distinctio, cumque voluptatum
          minima voluptates totam alias!
        </p>
      </div>
    </div>
  );
};

export default AccountName;
