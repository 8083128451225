// modules & hooks
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MEDIA_QUERIES } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";
// antd
import { Col, Form, Input, Row } from "antd";

const AddressDetails = ({ isFinished, onChangePhone, phoneNumber }) => {
  // locall states
  const [countryCode, setCountryCode] = useState("");
  // window size
  const windowSize = useWindowSize();

  return (
    <div className="kyc-address-details kyc-form">
      <h3 className="container-heading">Address Details</h3>
      <Row gutter={16}>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="address1"
            label="Address 1"
            rules={[
              {
                required: true,
                message: "Address 1 is required!",
              },
              {
                max: 100,
                message: "Address 1 should be less than 100 symbols!",
                whitespace: true,
              },
            ]}>
            <Input placeholder="Address 1" disabled={isFinished} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="address2"
            label="Address 2"
            rules={[
              {
                required: true,
                message: "Address 2 is required!",
              },
              {
                max: 100,
                message: "Address 2 should be less than 100 symbols!",
                whitespace: true,
              },
            ]}>
            <Input placeholder="Address 2" disabled={isFinished} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="city"
            label="City"
            rules={[
              {
                required: true,
                message: "City is required!",
              },
              {
                max: 25,
                message: "City should be less than 25 symbols!",
                whitespace: true,
              },
            ]}>
            <Input placeholder="City" disabled={isFinished} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
          <Form.Item
            name="zip"
            label="Postal Code"
            rules={[
              {
                required: true,
                message: "Postal code is required!",
              },
              {
                pattern: /^\d+$/,
                message: "Postal code is not valid!",
                whitespace: true,
              },
            ]}>
            <Input placeholder="Postal code" disabled={isFinished} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={24}>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
              },
              {
                min: 10,
                message: "The Phone Number should be more than 7 symbols!",
                whitespace: true,
              },
            ]}>
            <PhoneInput
              country={countryCode.toLocaleLowerCase()}
              style={{ width: "100%" }}
              value={phoneNumber}
              onChange={onChangePhone}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default AddressDetails;
