// modules & hooks
import React, { useState } from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../../../../constants";
// antd
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Space, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
// assets
import DownloadIcon from "../../../../assets/img/kyc/download-arrow.png";
import { useSelector } from "react-redux";
import { useEffect } from "react";

// props and methods for upload fields
const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const BeneficialOwnerDocVerification = () => {
  // selectors
  const isKybSubmitted = useSelector((state) => state.kyb.isKybSubmitted);

  const [isIdSelected, setIsIdSelected] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  // antd select methods
  const { Option } = Select;

  function handleChange(value, type) {
    console.log(`selected ${value}`);
    if (type === "id") {
      setIsIdSelected(true);
    }
    if (type === "other") {
      setIsOtherSelected(true);
    }
  }

  // selectors
  const kybData = useSelector((state) => state.kyb);

  const [formData, setFormData] = useState(kybData.step2.entityDocumentVerification);

  useEffect(() => {
    setFormData(kybData.step2.entityDocumentVerification);
  }, []);

  // window size
  const windowSize = useWindowSize();

  return (
    <div className="kyb-contact-info">
      <h3 className="container-heading">Entity Document Verification</h3>

      <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
        <Form.Item
          name="taxCountry"
          label="Tax Country"
          rules={[
            {
              required: true,
            },
          ]}
          style={{ display: "flex", flexDirection: "column" }}>
          <Select
            defaultValue={formData?.taxCountry || "+91"}
            onChange={handleChange}
            disabled={isKybSubmitted}>
            <Option value="+91">+91</Option>
            <Option value="+20">+20</Option>
          </Select>
        </Form.Item>
      </Col>

      <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
        <Form.Item
          name="governmentIssuedId"
          label="Government Issued ID"
          rules={[
            {
              required: true,
            },
          ]}>
          <Input
            type="text"
            defaultValue={formData?.registrationNumber}
            placeholder="Enter Registration Number"
            disabled={isKybSubmitted}
          />
        </Form.Item>
      </Col>

      <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
        <Row gutter={16}>
          <Form.Item
            name="dateOfBirth"
            label="Date Of Birth"
            rules={[
              {
                required: true,
              },
            ]}>
            <Row>
              <Col>
                <Space direction="vertical">
                  <DatePicker size="default" />
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Row>
      </Col>

      <Col>
        <Form.Item
          name="idDocument"
          label="ID Document"
          rules={
            [
              // {
              //   required: true,
              // },
            ]
          }>
          <Select
            defaultValue={formData?.taxCountry || "Some ID 1"}
            onChange={handleChange}
            disabled={isKybSubmitted}>
            <Option value="Some ID 1">Some ID 1</Option>
            <Option value="Some ID 2">Some ID 2</Option>
          </Select>
        </Form.Item>
      </Col>

      <Col>
        <Form.Item
          name="frontOfID"
          label="Front Of ID"
          rules={
            [
              // {
              //   required: true,
              // },
            ]
          }>
          {isKybSubmitted ? (
            <div className="download-box">
              <span>{formData["uploadIdentityDocument"]?.file?.name}</span>{" "}
              <Button prefix={DownloadIcon}>Download Document</Button>
            </div>
          ) : (
            <Upload {...props} multiple={false} className="upload-box">
              <Button disabled={isKybSubmitted}>
                Drag files here or <span>Browse files</span>
              </Button>
            </Upload>
          )}
        </Form.Item>
      </Col>

      <Col>
        <Form.Item
          name="backOfID"
          label="Back Of ID"
          rules={[
            {
              required: true,
            },
          ]}>
          {isKybSubmitted ? (
            <div className="download-box">
              <span>{formData["uploadIdentityDocument"]?.file?.name}</span>{" "}
              <Button prefix={DownloadIcon}>Download Document</Button>
            </div>
          ) : (
            <Upload {...props} multiple={false} className="upload-box">
              <Button disabled={isKybSubmitted}>
                Drag files here or <span>Browse files</span>
              </Button>
            </Upload>
          )}
        </Form.Item>
      </Col>
    </div>
  );
};

export default BeneficialOwnerDocVerification;
