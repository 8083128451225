// modules & hooks
import React, { useState, useEffect } from "react";
import useWindowSize from "../hooks/useWindowSize";
import { MEDIA_QUERIES, PROFILE_INFO_CTAEGORIES } from "../constants";
import { useSelector } from "react-redux";
import { ACCOUNT_TYPES } from "../constants";
import moment from "moment";
// components
import InfoBox from "../components/ProfileComponents/InfoBox";
// services
import { getPersonalInfo, getUser, fetchDoc, getCompanyInfo } from "../../services/users.js";
import { getCountries } from "../../services/countries";
import { USER_STATUSES, US_STATES, BALANCE_STATUSES, USER_TYPE } from "eurst-shared/src/enums";
import { FORMAT_FOR_DATE } from "eurst-admin-part/src/constants/constants";
// assets
import UserAvatarActive from "../assets/img/profile/user-avatar-active.png";
import UserAvatar from "../assets/img/profile/user-avatar.png";
import KycInfoActive from "../assets/img/profile/kyc-info-active.png";
import KycInfo from "../assets/img/profile/kyc-info.png";
import UploadedDocsActive from "../assets/img/profile/uploaded-docs-active.png";
import UploadedDocs from "../assets/img/profile/uploaded-documents.png";
import WalletInfoActive from "../assets/img/profile/wallet-info-active.png";
import WalletInfo from "../assets/img/profile/wallet-info.png";
// styles
import "../styles/PagesStyles/ProfileStyles/index.scss";

const ProfilePage = () => {
  // states
  const [activeInfoCateg, setActiveInfoCateg] = useState(PROFILE_INFO_CTAEGORIES.BASIC_INFO);
  const [basicInfo, setBasicInfo] = useState({
    fullName: "",
    email: "",
    status: "",
    dob: "",
    gender: "",
    phoneNumber: "",
    fullAddress: "",
    residenceCountry: "",
    citizenship: "",
  });
  const [kycInfo, setKycInfo] = useState({
    primTrustAccountId: "",
    taxIdNumber: "",
  });
  const [walletInfo, setWalletInfo] = useState({
    wallet: "",
    eurstBalance: "",
    walletAddress: "",
  });
  const [uploadDoc, setUploadDoc] = useState("");

  // fetch api data
  const fetchApiData = async () => {
    const { data: userData } = await getUser();
    console.log("USER DATA", userData);
    const { data: personlaData } = await getPersonalInfo();
    console.log("Personal DATA", personlaData);
    if (userData.type === USER_TYPE.company) {
      const companyData = await getCompanyInfo();
      console.log("Comapany DATA", companyData);
    }
    const countriesData = await getCountries();

    const taxState =
      personlaData.data?.taxState &&
      US_STATES.find((item) => item.code === personlaData.data.taxState);

    setBasicInfo({
      fullName:
        userData?.firstName && userData?.lastName
          ? `${userData?.firstName} ${userData?.lastName}`
          : "N/A",
      email: userData.email || "N/A",
      status: userData.status,
      dob:
        moment(personlaData?.dateOfBirth ? personlaData.dateOfBirth : []).format(FORMAT_FOR_DATE) ||
        "N/A",
      gender: personlaData?.gender || "N/A",
      phoneNumber: personlaData?.phoneNumber || "N/A",
      fullAddress: personlaData?.address?.address1
        ? `${personlaData?.address?.address1} ${personlaData?.address?.city || ""} ${
            taxState || personlaData?.address?.region || ""
          } ${personlaData?.address?.zip || ""}`
        : "N/A",
      residenceCountry: personlaData?.countryOfResidence
        ? countriesData.find((e) => e.code === personlaData?.countryOfResidence).name
        : "N/A",
      citizenship: personlaData?.citizenship
        ? countriesData.find((e) => e.code === personlaData?.citizenship).name
        : "N/A",
    });

    setKycInfo({
      primTrustAccountId: userData?.ptAccountId || "N/A",
      taxIdNumber: personlaData?.taxIdNumber || "N/A",
    });

    setWalletInfo({
      wallet: userData?.ethAddress || "N/A",
      eurstBalance: userData?.balance || "N/A",
      walletAddress: "",
    });
  };

  useEffect(() => {
    fetchApiData().then();
  }, []);

  const chooseInfoCategory = (category) => setActiveInfoCateg(category);

  // cheching account type
  const isBusinessAccount =
    useSelector((state) => state.shared.accountType) === ACCOUNT_TYPES.business;

  // window size
  const windowSize = useWindowSize();

  return (
    <section
      className={`profile ${
        windowSize.width > MEDIA_QUERIES.MD ? "container-fluid" : "responsive-md container"
      }`}>
      <div className="info-categories">
        <ul className="scrollable">
          <li
            className={`infoCategory ${
              activeInfoCateg === PROFILE_INFO_CTAEGORIES.BASIC_INFO ? "active" : null
            }`}
            onClick={() => chooseInfoCategory(PROFILE_INFO_CTAEGORIES.BASIC_INFO)}>
            {activeInfoCateg === PROFILE_INFO_CTAEGORIES.BASIC_INFO ? (
              <img src={UserAvatarActive} alt="Profile page - Basic Information" />
            ) : (
              <img src={UserAvatar} alt="Profile page - Basic Information" />
            )}
            <span>Basic Information</span>
          </li>
          {isBusinessAccount ? (
            <li
              className={`infoCategory ${
                activeInfoCateg === PROFILE_INFO_CTAEGORIES.KYB_INFO ? "active" : null
              }`}
              onClick={() => chooseInfoCategory(PROFILE_INFO_CTAEGORIES.KYB_INFO)}>
              {activeInfoCateg === PROFILE_INFO_CTAEGORIES.KYB_INFO ? (
                <img src={KycInfoActive} alt="Profile page - KYC Information" />
              ) : (
                <img src={KycInfo} alt="Profile page - KYC Information" />
              )}
              <span>KYB Information</span>
            </li>
          ) : (
            <li
              className={`infoCategory ${
                activeInfoCateg === PROFILE_INFO_CTAEGORIES.KYC_INFO ? "active" : null
              }`}
              onClick={() => chooseInfoCategory(PROFILE_INFO_CTAEGORIES.KYC_INFO)}>
              {activeInfoCateg === PROFILE_INFO_CTAEGORIES.KYC_INFO ? (
                <img src={KycInfoActive} alt="Profile page - KYC Information" />
              ) : (
                <img src={KycInfo} alt="Profile page - KYC Information" />
              )}
              <span>KYC Information</span>
            </li>
          )}
          <li
            className={`infoCategory ${
              activeInfoCateg === PROFILE_INFO_CTAEGORIES.WALLET_INFO ? "active" : null
            }`}
            onClick={() => chooseInfoCategory(PROFILE_INFO_CTAEGORIES.WALLET_INFO)}>
            {activeInfoCateg === PROFILE_INFO_CTAEGORIES.WALLET_INFO ? (
              <img src={WalletInfoActive} alt="Profile page - Wallet Information" />
            ) : (
              <img src={WalletInfo} alt="Profile page - Wallet Information" />
            )}
            <span>Wallet Information</span>
          </li>
          <li
            className={`infoCategory ${
              activeInfoCateg === PROFILE_INFO_CTAEGORIES.UPLOADED_DOCS ? "active" : null
            }`}
            onClick={() => chooseInfoCategory(PROFILE_INFO_CTAEGORIES.UPLOADED_DOCS)}>
            {activeInfoCateg === PROFILE_INFO_CTAEGORIES.UPLOADED_DOCS ? (
              <img src={UploadedDocsActive} alt="Profile page - Uploaded Documents" />
            ) : (
              <img src={UploadedDocs} alt="Profile page - Uploaded Documents" />
            )}
            <span>Uploaded Documents</span>
          </li>
        </ul>
      </div>
      <InfoBox
        infoCategory={activeInfoCateg}
        basicInfo={basicInfo}
        kycInfo={kycInfo}
        walletInfo={walletInfo}
        uploadDoc={uploadDoc}
      />
    </section>
  );
};

export default ProfilePage;
