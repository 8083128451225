/* eslint-disable no-unused-vars */
// modules
import React, { useState, useContext } from "react";
import moment from "moment";
// assets
import SuccessIcon from "../../assets/img/popup/success-icon.png";
// components
import PersonalDetails from "./PersonalDetails";
import AddressDetails from "./AddressDetails";
import UploadDocuments from "./UploadDocuments";
// antd
import { Form, Button, Checkbox, Modal } from "antd";
// services
import { Context } from "../../../store";
import { getInstance } from "../../../utils/helpers";
import { PT_DOCUMENT_TYPES } from "eurst-shared/src/enums";
import PrivacyPolicy from "../RegisterComponents/PrivacyPolicy";
import { REQUIRED_PT_DOC_TYPES } from "../../../constants/constants";
import { setKycStatus, postPersonalInfo, getPreviewAgreement } from "../../../services/users";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const KYCForm = () => {
  // antd modal states & methods
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [form] = Form.useForm();
  const [state, dispatch] = useContext(Context);
  const [previewAgreement, setPreviewAgreement] = useState("");

  // popups states
  const [privacyVisible, setPrivacyVisible] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "You KYC has been submitted successfully. Please allow us 48 verify your submission."
  );

  const [formData, setFormData] = useState(null);
  const [isFinished, setIsFinished] = useState(false);

  // popup methods
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsFinished(true);
  };

  const formRef = React.createRef();

  const onChangePhone = (phoneNumber) => {
    form.setFieldsValue({ phoneNumber: phoneNumber });
    setPhoneNumber(phoneNumber);
  };

  // privacy popup methods
  const handleOkPrivacy = () => {
    setPrivacyVisible(false);
  };

  const handleCancelPrivacy = () => {
    setPrivacyVisible(false);
  };

  const showPrivacyPolicyModal = () => {
    setPrivacyVisible(true);
  };

  // list of documents for identity confirmation
  const identityDocsOptions = PT_DOCUMENT_TYPES.filter((docType) =>
    REQUIRED_PT_DOC_TYPES.some((id) => id === docType.id)
  );

  // list of other documents should exclude all documents for identity confirmation
  const otherDocsOptions = PT_DOCUMENT_TYPES.filter(
    (docType) => !identityDocsOptions.some((type) => type.id === docType.id)
  );

  const onFinish = async (values) => {
    console.log(values);
    values.dateOfBirth = moment(values.dateOfBirth).format("YYYY-MM-DD");
    const personalInfo = { ...values };
    const residenceInfo = {
      address: {
        ...values,
      },
    };

    try {
      const updatedUser = await setKycStatus();
      dispatch({ type: "SET_USER", payload: updatedUser.data });

      const payload = {
        ...personalInfo,
        ...residenceInfo,
        phoneNumber,
      };
      const previewAgreementHTML = await getPreviewAgreement(payload);
      setPreviewAgreement(previewAgreementHTML.data.content);

      const postPayload = {
        address1: values.address1,
        address2: values.address2,
        citizenship: values.citizenship,
        city: values.city,
        countryOfResidence: values.countryOfResidence,
        dateOfBirth: values.dateOfBirth,
        firstName: values.firstName,
        gender: values.gender,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        taxIdNumber: values.taxIdNumber,
        zip: values.zip,
      };

      const rePayload = {
        address: { ...postPayload },
      };

      const finalPayload = {
        ...postPayload,
        ...rePayload,
      };

      // section 2
      await postPersonalInfo(finalPayload, finalPayload.address);
      // upload section

      const {
        identityDocument,
        uploadIdentityDocument,
        otherDocument,
        uploadOtherDocument,
      } = values;

      const fileUploadHelper = async (docId, dockFile) => {
        const uploadUrl = `/api/users/personal-info/upload/${docId}`;

        const data = new FormData();
        data.append("file", dockFile?.file);

        const defaultHeaders = getInstance().defaults.headers;
        const config = {
          headers: {
            ...defaultHeaders,
            "content-type": "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
          },
        };
        const parent = await getInstance().post(uploadUrl, data, config);
      };

      if (identityDocument) {
        await fileUploadHelper(identityDocument, uploadIdentityDocument);
      }

      if (otherDocument) {
        await fileUploadHelper(otherDocument, uploadOtherDocument);
      }
      await setKycStatus();

      showModal();
      history.push("/main/dashboard");
    } catch (e) {
      console.log("Error", e);
    }
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  // if (isFinished) return <h1>Read Only Mood!</h1>;

  return (
    <div className="kyc-form-container">
      <Form {...layout} ref={formRef} form={form} name="control-ref" onFinish={onFinish}>
        <div className="kyc-form-heading">
          <h3 className="container-heading">KYC</h3>
          <p>This step is necessary to get access to the full range of EURST operations.</p>
        </div>
        <PersonalDetails isFinished={isFinished} />
        <AddressDetails
          isFinished={isFinished}
          onChangePhone={onChangePhone}
          phoneNumber={phoneNumber}
        />
        <UploadDocuments
          isFinished={isFinished}
          formData={formData}
          identityDocsOptions={identityDocsOptions}
          otherDocsOptions={otherDocsOptions}
          form={form}
        />
        {!isFinished ? (
          <>
            <div className="kyc-warning-box">
              <h3>Note</h3>
              <ol>
                <li>The KYC procedure check requires 1-5 Days.</li>
                <li>You will receive an email when it is complete.</li>
                <li>After successful completion you will have access to the services.</li>
              </ol>
            </div>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error("Should accept agreement")),
                },
              ]}
              {...tailFormItemLayout}>
              <Checkbox>
                <span>I have read the </span>
                <div className="privacy-policy-container">
                  <Button type="primary" onClick={showPrivacyPolicyModal} className="link">
                    Privacy policy
                  </Button>
                  <Modal
                    className="privacy-policy-popup"
                    visible={privacyVisible}
                    centered
                    okText="Done"
                    onOk={handleOkPrivacy}
                    cancelText="Close"
                    onCancel={handleCancelPrivacy}>
                    <PrivacyPolicy handleCancelPrivacy={handleCancelPrivacy} />
                  </Modal>
                </div>
                <span>
                  and give my personal consent to check the data provided in this KYC form.
                </span>
              </Checkbox>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit KYC
              </Button>
              <Modal
                className="register-success"
                title={
                  <>
                    <img src={SuccessIcon} alt="Success icon" />
                    <span>Congratulations!</span>
                  </>
                }
                visible={isModalVisible}
                centered
                okText="Done"
                onOk={handleOk}
                confirmLoading={confirmLoading}>
                <p>{modalText}</p>
              </Modal>
            </Form.Item>
          </>
        ) : null}
      </Form>
    </div>
  );
};
export default KYCForm;
